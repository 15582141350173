import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const boxStyles = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 1,
    m: 1,
    bgcolor: theme.palette.mode === 'dark' ? '#101010' : '#fff',
    color: theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
    borderRadius: 2,
    fontSize: '0.875rem',
    fontWeight: '700'
})

const AdvertisementDataBox = ({ title, amount, color }) => {
    const theme = useTheme()
    return (
        <Box
            component='div'
            sx={{
                ...boxStyles(theme),
                backgroundColor: color,
                color: theme.palette.getContrastText(color),
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
            }}
        >
            <span style={{ fontSize: '23px', color: 'white' }}>{amount?.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</span>
            <span style={{
                fontSize: '12px',
                color: 'white',
                backgroundColor: color === '#D25E5E' ? '#CA4646' : color === '#34C085' ? '#249968' : '#3B6DB8',
                padding: '4px 8px',
                borderRadius: '6px',
                cursor: 'pointer',
                display: 'inline-block',
                textAlign: 'center',
                fontWeight: 'bold',
            }}>{title}</span>
        </Box>
    )
}

AdvertisementDataBox.propTypes = {
    title: PropTypes.string.isRequired,
    isValue: PropTypes.bool.isRequired,
    amount: PropTypes.number,
    difference: PropTypes.number.isRequired,
    isDecreasing: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isSales: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired
}

export default AdvertisementDataBox