import { configureStore } from '@reduxjs/toolkit'
import advertisementReducer from '../slice/advertisementSlice'

const store = configureStore({
  reducer: {
    advertisement: advertisementReducer,
  },
})

export default store

