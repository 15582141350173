import React, { useState, useEffect, useRef } from 'react'
import { IoMdArrowBack } from 'react-icons/io'
import { CCol, CRow } from '@coreui/react'
import { Button, IconButton, Skeleton } from '@mui/material'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter, checkValues, isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import Carousel from './Carousel'
import Magnifier from '../../uiElements/Magnifier'
import Status from 'components/common/status/VerticalStatus'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './View.css'
import Images from 'data/Images'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'



const View = (props) => {
  const {design}=props
  const { product } = props
  const [opensku, setOpensku] = useState(false)
  const [metric,setMetric] =useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  let [selectedImage, setSelectedImage] = useState('')
  const carouselItemsRef = useRef([])

  const getMaterial = (materials) => {
    let product = ''
    product = materials.map((data) => {
      return capitalizeFirstLetter(data.name) + ': ' + data.percentage + '%  '
    })
    return product
  }

  // For carousel
  useEffect(
    () => {
      if (product?.images && product?.images[0]) {
        carouselItemsRef.current = carouselItemsRef.current.slice(0, product?.images.length)
        setSelectedImage(product?.images[0])
      }
    }, [product?.images])

    
  // For Selecting Image
  const handleSelectedImageChange = (newIndex) => {
    if (product?.images && product?.images.length > 0) {
      setSelectedImage(product?.images[newIndex])
      setSelectedImageIndex(newIndex)

      // Moving the Carousel towards the current index
      if (carouselItemsRef?.current[newIndex]) {
        carouselItemsRef?.current[newIndex]?.scrollIntoView({
          inline: 'center',
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  }

  const handleRightClick = () => {
    if (product?.images && product?.images.length > 0) {
      let newIndex = selectedImageIndex + 1

      // Start carousel from begnning
      if (newIndex >= product?.images.length) {
        newIndex = 0
      }
      handleSelectedImageChange(newIndex)
    }
  }

  const handleLeftClick = () => {
    if (product?.images && product?.images.length > 0) {
      let newIndex = selectedImageIndex - 1

      // Start carousel from behind
      if (newIndex < 0) {
        newIndex = product?.images.length - 1
      }
      handleSelectedImageChange(newIndex)
    }
  }
  const error = undefined
  const isLoading = false
 
  return (
    <div data-testid='product-quick-view'>
      {error ? (
        <div style={{ height: '35rem' }}>
          <Status
            datatestid='status-container-t'
            image={Images.ErrorOccured}
            message='Something Went Wrong!!!'
            info={error}
            error={error}
            height='35rem'
            imgheight='15rem'
            extraClasses='pt-5'
          />
        </div>
      ) : (
        <CRow>
          <CCol xs={12} md={6} lg={6} className='image-column'>
            {opensku ? (
              <IconButton
                aria-label='back'
                onClick={() => {
                  setOpensku(false)
                  setMetric([])
                }}
                style={{
                  position: 'inherit',

                  paddingRight: '10',

                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <IoMdArrowBack />
              </IconButton>
            ) : (
              ''
            )}
            {isLoading ? (
              <Skeleton variant='rectangular' height='50vh' />
            ) : (
              <div className='product-image'>
                {!props.slideShow ? (
                  <Magnifier src={selectedImage} width='100%' height='50vh' data-testid='magnifier' />
                ) : (
                  <div
                    style={{
                      position: 'relative',
                      height: '85vh',
                      width: '100%'
                    }}
                  >
                    <img
                      src={selectedImage}
                      style={{
                        height: '100%',
                        width: '100%',
                        borderRadius: '5px'
                      }}
                    />
                  </div>
                )}

                {/* Carousel */}
                {!props.slideShow && (
                  <Carousel
                    images={product?.images}
                    handleLeftClick={handleLeftClick}
                    handleRightClick={handleRightClick}
                    carouselItemsRef={carouselItemsRef}
                    handleSelectedImageChange={handleSelectedImageChange}
                    selectedImageIndex={selectedImageIndex}
                  />
                )}
              </div>
            )}
          </CCol>

          <CCol xs={12} md={6} lg={6} className='card-box-container'>
            <div className='product-attributes-container'>
              {opensku ? (
                <>
                  <div className='pac-name'>{metric?.sku}</div>
                  <div className='pac-code'>
                  <strong>Design:</strong>
                    {product?.productDesign[0]?.design}
                  </div>
                </>
              ) : (
                <>
                  {/* <div className='pac-name'>{product?.sku}</div> */}
                  <div className='pac-name'>
                    <strong>Design:</strong> {product?.productDesign[0]?.design}
                  </div>
                </>
              )}

              <hr />

              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {isNotNullUndefinedOrEmpty(product?.properties[0]?.construction) && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Construction</div>
                      <div className='col detail-info'>
                        {capitalizeFirstLetter(product?.properties[0]?.construction)}
                      </div>
                    </div>
                  )}
                </>
              )}
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {isNotNullUndefinedOrEmpty(product?.properties[0]?.content) && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Content</div>
                      <div className='col detail-info'>{product?.properties[0]?.content.join(', ')}</div>
                    </div>
                  )}
                </>
              )}
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {isNotNullUndefinedOrEmpty(product?.allColors) && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Colors</div>
                      <div className='col detail-info'>{product?.allColors}</div>
                    </div>
                  )}
                </>
              )}

              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {!!product?.shipping[0]?.vendor && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Vendor Alias</div>
                      <div className='col detail-info'>{product?.shipping[0]?.vendorName}</div>
                    </div>
                  )}
                </>
              )}

              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {isNotNullUndefinedOrEmpty(product?.materials) &&
                    product?.materials &&
                    product?.materials[0]?.percentage > 0 && (
                      <div className='row product-attribute'>
                        <div className='col-4 detail-title'>Materials</div>
                        <div className='col detail-info'>
                          {Array.isArray(product?.materials) ? getMaterial(product?.materials) : 'hh'}
                        </div>
                      </div>
                    )}
                </>
              )}
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {checkValues('Weight', product) !== '' && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Weight</div>
                      <div className='col detail-info'>{checkValues('Weight', product)}</div>
                    </div>
                  )}
                </>
              )}
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {checkValues('cost', product) !== '' && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Cost</div>
                      <div className='col detail-info'>{checkValues('cost', product)}</div>
                    </div>
                  )}
                </>
              )}
              {opensku ? (
                <>
                  {!!product?.measurements && isNotNullUndefinedOrEmpty(product?.measurements[0]) && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Metric Size</div>
                      <div className='col detail-info'>
                        {metric?.measurements[0]?.metricSize}{' '}
                        {product?.measurements[0]?.metricSizeUnit ? product?.measurements[0]?.metricSizeUnit : 'CM'}
                      </div>
                    </div>
                  )}
                  {!!product?.measurements && isNotNullUndefinedOrEmpty(product?.shipping[0]) && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Price</div>
                      <div className='col detail-info'>
                        {metric?.shipping[0]?.fobCostPerPc}{' '}
                        {product?.shipping[0]?.currency ? product?.shipping[0]?.currency : 'USD'}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <TableContainer component={Paper} style={{ height: "250px", overflow: "auto"}}>
                  <Table sx={{ minWidth: 350 }} aria-label='sticky table' >
                    <TableHead>
                      <TableRow>
                        <TableCell>SKU</TableCell>
                        <TableCell align='right'>Metric Size</TableCell>
                        <TableCell align='right'>Price&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {design?.map((row) => (
                      <TableRow key={row?.sku} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component='th' scope='row'>
                            <Button
                              variant='outlined'
                              onClick={() => {
                                setMetric(row)
                                setOpensku(true)
                      }}
                            >
                              {row?.sku}
                            </Button>
                          </TableCell>
                          <TableCell align='right'>
                            {(row.measurements[0]?.metricSize !== "null" && row.measurements[0]?.metricSize)
                            ? `${row.measurements[0]?.metricSize} ${row.measurements[0]?.metricSizeUnit}`
                            : ''}
                          </TableCell>
                         
                          <TableCell align='right'>
                            {(row.shipping[0]?.fobCostPerPc !== "null" && row.shipping[0]?.fobCostPerPc)
                            ? `${ Math.round(row.shipping[0]?.fobCostPerPc  * 100) / 100} ${row.shipping[0]?.currency}`
                            : ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </CCol>
        </CRow>
      )}
    </div>
  )
}

View.propTypes = {
  productId: PropTypes.string,
  item: PropTypes.object,
  setOpen: PropTypes.func,

  IsShortlistedproduct: PropTypes.bool,
  removeFromShortlist: PropTypes.func,
  addtoShortlist: PropTypes.func,
  Open: PropTypes.bool,
  isUserView: PropTypes.bool,
  id: PropTypes.string,
  deleteproduct: PropTypes.func,
  product: PropTypes.object,
  needReview: PropTypes.bool,
  approveBtnClick: PropTypes.func,
  index: PropTypes.number,
  slideShow: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  design:PropTypes.array
}

export default View
