import { React, useState } from 'react'
import { TableHead, TableSortLabel, TableRow, TableCell, Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import CatalogueStaus from 'assets/gifs/cataloguestatus.gif'
import Modal from 'components/uiElements/modal/Dialog'
import './DataTable.css'

function TableHeader (props) {
  const [open, setOpen] = useState(false)
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead >
      <TableRow>
        {props.enableSelection && (
          <TableCell padding='checkbox'>
            <Checkbox
              data-testid='checkbox11'
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all Products'
              }}
            />
          </TableCell>
        )}
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={`${headCell.isSticky ? 'sticky-column row-color' : 'row-color'}`}
            align={headCell.isCentered ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              backgroundColor: '#f2f2f2 ',
              color: 'grey'
            }}
          >
            {/* eslint-disable-next-line multiline-ternary */}
            {!headCell.disableSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                data-testid={`${headCell.id}`}
              >
                {headCell.label}

              </TableSortLabel>
            ) : headCell.tooltip ? (
              <>
                <span>
                  {headCell.label}
                  <sup>
                    &nbsp;
                    <AiOutlineInfoCircle
                      size={15}
                      onClick={() => setOpen(true)}
                      style={{ cursor: 'pointer', color: '#1976d2' }}
                      data-testid='icon'
                    />
                  </sup>
                  <Modal
                    open={open}
                    fullWidth
                    maxWidth='sm'
                    // title='Catalogue Status Transition'
                    content={<img src={CatalogueStaus} alt='flow' width='100%' height='100%' />}
                    handleClose={() => setOpen(false)}
                  />
                </span>
              </>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

TableHeader.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array,
  enableSelection: PropTypes.bool
}

export default TableHeader
