import React from 'react'
import PropTypes from 'prop-types'
import { getChatMessageDateTime } from 'shared/util/helper'

import './index.css'
import Images from 'data/Images'

const Buyer = (props) => {
  return (
    <div className='d-flex flex-row justify-content-start' data-testid='buyer' key={props.index}>
      <img src={Images.UserChatImage} alt='avatar 1' style={{ width: '45px', height: '100%' }} />
      <div className='message-text'>
      <p className='small  pb-4 ms-4 mb-4 rounded-2 text-muted float-start'>
          {/* {getChatMessageDateTime(props.createdOn)} */}
          {props.name}
        </p>
        <p className='small pt-4 pr-3 ms-3 pb-2 rounded-3' style={{ backgroundColor: '#f5f6f7' }}>
          {props.message}
        </p>
        
        <p className='small ms-3 mb-3 rounded-3 text-muted float-end'>
          {getChatMessageDateTime(props.createdOn)}
        </p>
      </div>
    </div>
  )
}

Buyer.propTypes = {
  message: PropTypes.string,
  createdOn: PropTypes.instanceOf(Date),
  index: PropTypes.number,
  name:PropTypes.string
}

export default Buyer
