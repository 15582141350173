import React from 'react'
import { IconButton } from '@mui/material'
import { FaPaperPlane } from 'react-icons/fa'
import PropTypes from 'prop-types'

import './Footer.css'
import { BiImageAdd } from 'react-icons/bi'

const NewMessage = ({ onStateChange, message, sendMessage, logo, selectImage,handleUpload,image }) => {
  return (
    <div className='card' data-testid='footer' style={{ borderRadius: '20px' }}>
      <div className='card-body text-muted d-flex justify-content-start align-items-center pe-3  mt-2'>
        <img
          src={logo}
          alt='company'
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%'
          }}
        />
        <input
          type='text'
          className='form-control form-control-lg'
          placeholder='Type a message here'
          onChange={(e) => onStateChange(e)}
          value={message}
          contentEditable
        />
        <IconButton className='ms-3'>
          <input
            accept='image/*,.pdf'
            id='icon-button-file'
            className='sc-user-input--send-icon-wrapper'
            type='file'
            style={{ display: 'none' }}
            onChange={(e) => selectImage(e)}
          />
          <label htmlFor='icon-button-file'>
            <BiImageAdd className='send-message-btn' />
          </label>
        </IconButton>
        {Object.keys(image).length !== 0 ? (
          <IconButton className='ms-3' onClick={()=>handleUpload()}>
          <FaPaperPlane className='send-message-btn' />
        </IconButton>
        ) : (
          <IconButton className='ms-3' onClick={()=>sendMessage(message,"text")}>
          <FaPaperPlane className='send-message-btn' />
        </IconButton>
        )}
        
      </div>
    </div>
  )
}

NewMessage.propTypes = {
  onStateChange: PropTypes.func,
  message: PropTypes.string,
  handleKeypress: PropTypes.func,
  sendMessage: PropTypes.func,
  selectImage: PropTypes.func,
  handleUpload: PropTypes.func,
  logo: PropTypes.string,
  image: PropTypes.object,

}

export default NewMessage
