import React from 'react'
import PropTypes from 'prop-types'
import { RiQuestionnaireFill } from 'react-icons/ri'
import { MdOutlineCancel } from 'react-icons/md'
import { useLocation } from 'react-router-dom'

const Header = (props) => {
  const { showQuery, setShowQuery } = props
  const routes = useLocation()

  return (
    <div className='sc-header' data-testid='header'>
      <img className='sc-header--img' src={props.imageUrl} width='70' height='70' alt='' />
      <div className='sc-header--team-name'>{props.teamName}</div>
      <div className='sc-header--team-name'></div>
      {routes?.pathname.includes('/order/') ? (
        <div className='sc-header--query'>
          {showQuery ? (
            <MdOutlineCancel size='1.8em' onClick={() => setShowQuery(!showQuery)} />
          ) : (
            <RiQuestionnaireFill size='1.8em' onClick={() => setShowQuery(!showQuery)} />
          )}
        </div>
      ) : (
        ''
      )}
      <div
        className='sc-header--close-button'
        onClick={props.onClose}
        role='button'
        tabIndex={0}
        onKeyUp={props.onClose}
      ></div>
    </div>
  )
}

Header.propTypes = {
  imageUrl: PropTypes.string,
  teamName: PropTypes.string,
  onClose: PropTypes.func,
  showQuery: PropTypes.bool,
  setShowQuery: PropTypes.func
}

export default Header
