import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import apiClient from 'api/apiClient'
import { endpoints } from 'api/endpoints'

const authService = {
  // Method for user signup
  signup: async (userObj) => {
    try {
      const response = await apiClient.post(endpoints.SIGNUP, userObj)
      return response
    } catch (error) {
      throw error
    }
  },

  updateUserDetails: async (userId, userObj) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_USER_DETAILS(userId), userObj)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method for vendor signup
  Vendorsignup: async (userObj) => {
    try {
      const response = await apiClient.post(endpoints.VENDOR_SIGNUP, userObj)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method for retail shop signup
  Retailsignup: async (userObj) => {
    try {
      const response = await apiClient.post(endpoints.RETAIL_SHOP_SIGNUP, userObj)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method for payment
  payment: async (paymentinfo) => {
    try {
      const response = await apiClient.post(endpoints.PAYMENT, paymentinfo)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method for authenticated payment
  authpayment: async (authInfo) => {
    try {
      const response = await apiClient.post(endpoints.AUTHENTICATED_PAYMENT, authInfo)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get payment information
  getPaymentInfo: async () => {
    try {
      const response = await apiClient.get(endpoints.PAYMENT_INFO)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method for user login
  login: async (email, pass) => {
    try {
      const response = await apiClient.post(endpoints.LOGIN, {
        email,
        password: pass,
        applicationCode: 4
      })
      if (isNotNullUndefinedOrEmpty(response.data)) {
        localStorage.setItem('userId', JSON.stringify(response.data))
      }
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to verify OTP
  verifyOtp: async (userId, otp) => {
    try {
      const response = await apiClient.post(endpoints.VERIFY_OTP, {
        userId,
        otpNumber: otp,
        applicationCode: 4
      })
      if (isNotNullUndefinedOrEmpty(response.data.token)) {
        localStorage.setItem('iauser', JSON.stringify(response.data))
      }
      localStorage.removeItem('userId')
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to resend OTP
  resendOtp: async (userId, applicationCode) => {
    try {
      const response = await apiClient.post(endpoints.RESEND_OTP, { userId: userId, applicationCode: applicationCode })
      if (isNotNullUndefinedOrEmpty(response.data.token)) {
        localStorage.setItem('iauser', JSON.stringify(response.data))
      }
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to logout
  logout: async () => {
    // ARUN - API CALL to expire token
    localStorage.removeItem('iauser')
  },

  // Method to change password
  changePassword: async (userCred) => {
    try {
      const response = await apiClient.post(endpoints.UPDATE_PASSWORD, userCred)
      if (isNotNullUndefinedOrEmpty(response.data.token)) {
        localStorage.setItem('iauser', JSON.stringify(response.data))
      }
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to update email
  updateEmail: async (email) => {
    return !!isNotNullUndefinedOrEmpty(email)
  },

  // Method to get company information
  getCompany: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_USER_COMPANY)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to reset password
  reset: async (email) => {
    try {
      const response = await apiClient.post(endpoints.RESET_EMAIL, { email })
      return response.data
    } catch (error) {
      throw error
    }
  },

  // Method to get user profile
  getProfile: async () => {
    try {
      const response = await apiClient.get(endpoints.USER_PROFILE)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to update user profile
  updateProfile: async (profile) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_USER, profile)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get user settings
  getSettings: async () => {
    try {
      const response = await apiClient.get(endpoints.USER_SETTINGS)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to update user settings
  updateSettings: async (request) => {
    try {
      const response = await apiClient.patch(endpoints.USER_SETTINGS, request)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get users
  getUsers: async (isActive) => {
    try {
      const response = await apiClient.get(endpoints.GET_COMPANY_USERS(isActive))
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get vendors
  getVendors: async (id) => {
    try {
      const response = await apiClient.get(endpoints.GET_VENDORS(id))
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get retail shops
  getRetail: async (id) => {
    try {
      const response = await apiClient.get(endpoints.GET_RETAIL_SHOP(id))
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get retail shop names
  getRetailshop: async () => {
    try {
      const response = await apiClient.get(endpoints.SHOP_NAME)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get email verification token
  getEmailVerificationToken: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_EMAIL_VERIFY_TOKEN)
      return response.data
    } catch (error) {
      throw error
    }
  },

  // Method to post email verification token
  postEmailVerificationToken: async (token) => {
    try {
      const response = await apiClient.post(endpoints.POST_EMAIL_VERIFY_TOKEN, { token })
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to refresh token
  refreshToken: async (companyId, shop_name) => {
    try {
      const response = await apiClient.patch(endpoints.REFRESH_TOKEN(companyId, shop_name))
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to update company information
  updateCompany: async (request) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_COMPANY, request)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to invite users via email
  invite: async (email) => {
    try {
      const response = await apiClient.post(endpoints.INVITE_EMAIL, { email })
      return response.data
    } catch (error) {
      throw error
    }
  }
}

export default authService
