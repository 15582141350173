
import React from 'react'
import { Grid, TableCell, TableRow, Box, Tabs, Tab, Typography, Skeleton, Tooltip, TextField, Button } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { IoIosInformationCircleOutline } from "react-icons/io"
import { MdEuroSymbol } from "react-icons/md"
import { PieChart } from '@mui/x-charts/PieChart'
import { LinePlot } from '@mui/x-charts/LineChart'
import { useSalesHooks } from './useSalesHooks'
import CircularProgress from '@mui/material/CircularProgress'
import { AppBreadcrumb } from 'sections/layout/dashboard'
import PropTypes from 'prop-types'
import SalesDashboardTable from 'components/salesdashboard/salesdashboardTable'
import CustomisedInput from 'components/formElements/CustomisedInput'
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6"
import { ChartsLegend, ChartsTooltip, ChartsXAxis, ChartsYAxis, ResponsiveChartContainer } from '@mui/x-charts'
import Seo from 'components/common/seo'
import { BiImport } from 'react-icons/bi'
import ModalDialog from 'components/uiElements/modal/Dialog'

const SkeletonRow = ({ cols }) => (
    <TableRow>
        {Array.from({ length: cols }, (_, index) => (
            <TableCell key={index}>
                <Skeleton variant='text' />
            </TableCell>
        ))}
    </TableRow>
)

SkeletonRow.propTypes = {
    cols: PropTypes.number.isRequired
}

const Index = () => {
    const {
        shop,
        shipment,
        warehouse,
        showProduct,
        setShowProduct,
        groupDataValue,
        showSalesRevenue,
        setShowSalesRevenue,
        activeDateRange,
        setGroupDataValue,
        setSelectedShop,
        setSelectedWarehouse,
        setSelectedShipmentCountry,
        handleApplyFilter,
        getCurrentMonthMinusOne,
        getCurrentMonth,
        getCurrentWeekNumber,
        getLastWeekNumber,
        getCurrentQuarter,
        getLastQuarterNumber,
        getCurrentYearMinusOne,
        resetFilters,
        setPresetValue,
        presetValue,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        selectedShop,
        selectedShipmentCountry,
        selectedWarehouse,
        showFilterMessage,
        revenueData,
        unitSoldData,
        totalSales,
        total,
        chartData,
        setSelectedComparison,
        selectedComparison,
        selectedCommerceType,
        setSelectedCommerceType,
        setSelectedShopType,
        selectedShopType,
        setSearchSku,
        searchSku,
        handleSort,
        getColumnHeader,
        getIconStyle,
        loadedData,
        isLoadings,
        buttonLoading,
        tableContainerRef,
        viewType,
        setViewType,
        setLoadedData,
        setAllDataLoaded,
        setProductPage,
        setDesignPage,
        openModal,
        setOpenModal,
        fetchSalesHangfire,
        selectedSkuFilter,
        setSelectedSkuFilter,
    } = useSalesHooks()

    // const filterOptions = {
    //     "All": "All Product",
    //     "continued": "Active Product",
    //     "discontinued": "DisContinued Product"
    // }

    const handleSkuFilterChange = (e) => {
        const filterValue = e.target.value
        setSelectedSkuFilter(filterValue)
    }

    const [graphValue, setGraphValue] = React.useState(0)
    const handleGraphChange = (event, newValue) => {
        if (event === "GRAPH") {
            setShowSalesRevenue(!showSalesRevenue)
            setGraphValue(newValue)
        }
        else {
            setShowProduct(!showProduct)
        }
    }

    const handleGraphChangeTab = (type, newValue) => {
        setViewType(newValue === 0 ? 'product' : 'design')
        setLoadedData([])
        setAllDataLoaded(false)
        if (newValue === 0) {
            setProductPage(1)
        } else {
            setDesignPage(1)
        }
    }

    const lightColors = [
        '#ee56ff', '#737aff', '#007efe', '#8e73ff', '#b966ff',
        '#b2e8dc',
    ]

    // Utility function to add colors to data
    const addColorsToData = (data) => {
        return data?.map((item, index) => ({
            ...item,
            color: lightColors[index % lightColors.length]
        }))
    }

    const menuItems = [
        { value: 10, label: "Select a preset (Optional)" },
        { value: 80, label: "Yesterday Sales" },
        { value: 20, label: "Last 7 days" },
        { value: 100, label: getCurrentWeekNumber() },
        { value: 90, label: getLastWeekNumber() },
        { value: 30, label: "Last 30 days" },
        { value: 110, label: getCurrentMonth() },
        { value: 40, label: getCurrentMonthMinusOne() },
        { value: 50, label: getCurrentQuarter() },
        { value: 120, label: getLastQuarterNumber() },
        { value: 60, label: "Year-to-date(YTD)" },
        { value: 70, label: getCurrentYearMinusOne() }
    ]

    const salesData = chartData?.shopTypeData?.map((item, index) => ({
        id: index,
        value: item.totalUntaxed,
        label: selectedComparison === 'pp'
            ? `${item.shop_type} ${item.untaxedDiff < 0 ? String.fromCharCode(8595) : String.fromCharCode(8593)} ${item?.untaxedDiff?.toFixed(2)}%`
            : `${item.shop_type}`
    }))

    const quantityData = chartData?.shopTypeData?.map((item, index) => ({
        id: index,
        value: item.totalQty,
        label: selectedComparison === 'pp'
            ? `${item.shop_type} ${item.qtyDiff < 0 ? String.fromCharCode(8595) : String.fromCharCode(8593)} ${item?.qtyDiff?.toFixed(2)}%`
            : `${item.shop_type}`
    }))

    const salesWarehouseData = chartData?.warehouseData?.map((item, index) => ({
        id: index,
        value: item.totalUntaxed,
        label: `${item.warehouse} ${item.untaxedDiff < 0 ? String.fromCharCode(8595) : String.fromCharCode(8593)} ${item?.untaxedDiff?.toFixed(2)}%`
    }))

    const quantityWarehouseData = chartData?.warehouseData?.map((item, index) => ({
        id: index,
        value: item.totalQty,
        label: `${item.warehouse} ${item.qtyDiff < 0 ? String.fromCharCode(8595) : String.fromCharCode(8593)} ${item?.qtyDiff?.toFixed(2)}%`
    }))

    const salesShopData = chartData?.shopData?.map((item, index) => ({
        id: index,
        value: item.totalUntaxed,
        label: `${item.shop} ${item.untaxedDiff < 0 ? String.fromCharCode(8595) : String.fromCharCode(8593)} ${item?.untaxedDiff?.toFixed(2)}%`
    }))

    const quantityShopData = chartData?.shopData?.map((item, index) => ({
        id: index,
        value: item.totalQty,
        label: `${item.shop} ${item.qtyDiff < 0 ? String.fromCharCode(8595) : String.fromCharCode(8593)} ${item?.qtyDiff?.toFixed(2)}%`
    }))

    // const salesCountryData = chartData?.countryData?.map((item, index) => ({
    //     id: index,
    //     value: item.totalUntaxed,
    //     label: `${item.country} ${item.untaxedDiff < 0 ? String.fromCharCode(8595) : String.fromCharCode(8593)} ${item?.untaxedDiff?.toFixed(2)}%`
    // }))
    const salesCountryData = chartData?.countryData?.map((item, index) => {
        const totalUntaxedSum = chartData?.countryData?.reduce((sum, item) => sum + item.totalUntaxed, 0)
        const percentage = (item.totalUntaxed / totalUntaxedSum) * 100
        return {
            id: index,
            value: item.totalUntaxed,
            label: `${item.country} ${percentage.toFixed(2)} %`
        }
    })
    // const quantityCountryData = chartData?.countryData?.map((item, index) => ({
    //     id: index,
    //     value: item.totalQty,
    //     label: `${item.country} ${item.qtyDiff < 0 ? String.fromCharCode(8595) : String.fromCharCode(8593)} ${item?.qtyDiff?.toFixed(2)}%`
    // }))

    const quantityCountryData = chartData?.countryData?.map((item, index) => {
        const totalQtySum = chartData?.countryData?.reduce((sum, item) => sum + item.totalQty, 0)
        const percentage = (item.totalQty / totalQtySum) * 100
        return {
            id: index,
            value: item.totalQty,
            label: `${item.country} ${percentage.toFixed(2)} %`
        }
    })

    const quantityDifference = totalSales?.totals?.totalQuantity - totalSales?.ppTotals?.totalQuantity
    const percentageQuantityDifference = !isNaN(quantityDifference) && totalSales?.totals?.totalQuantity !== 0 ? ((quantityDifference / totalSales?.totals?.totalQuantity) * 100).toFixed(2) : 0

    const untaxedTotalDifference = totalSales?.totals?.totalUntaxedTotal - totalSales?.ppTotals?.totalUntaxedTotal
    const percentageUntaxedTotalDifference = !isNaN(untaxedTotalDifference) && totalSales?.totals?.totalUntaxedTotal !== 0 ? ((untaxedTotalDifference / totalSales?.totals?.totalUntaxedTotal) * 100).toFixed(2) : 0


    return (
        <>
            <Seo
                title='Sales Dashboard'
                description='Discover the World of Surya Purchase Order Management'
                image='https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png'
            />
            <section className='page-spacing'>
                <div className='container-fluid'>
                    <div className='common-card rounded-3' style={{ padding: '20px' }}>
                        <Grid className='d-flex justify-content-between' item lg={6} md={6} sm={6} xs={12}>
                            <h3 className='fw-bold advertisement-report' >Sales Dashboard <span ></span></h3>
                            <Button className='rounded-3' variant='outlined' onClick={() => { fetchSalesHangfire() }} style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}>
                                <BiImport className='me-2' size='1.0rem' />Import Sales Data</Button>
                        </Grid>
                        <div className='mb-3'>
                            <AppBreadcrumb></AppBreadcrumb>
                        </div>
                        {activeDateRange && (<Grid>
                            <Grid className='dash_six'>Active Date Range: {activeDateRange}</Grid>
                        </Grid>)}
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        {/* <InputLabel id="demo-simple-select-label">Dates Range presets</InputLabel> */}
                                        <Select
                                            value={presetValue}
                                            // displayEmpty
                                            // InputLabelProps={{ shrink: false }}
                                            onChange={(e) => setPresetValue(e.target.value)}
                                            // IconComponent={() => null}
                                            renderValue={(selected) => {
                                                if (!selected || selected === 10) {
                                                    return "Preset"
                                                }
                                                const selectedItem = menuItems.find(item => item.value === selected)
                                                return selectedItem ? selectedItem.label : ""
                                            }}
                                        >
                                            {menuItems.map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        <Select
                                            value={selectedComparison}
                                            displayEmpty
                                            InputLabelProps={{ shrink: false }}
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return "Comparison"
                                                }
                                                const menuItems = [
                                                    { value: 'pp', label: "Previous Period" },
                                                    { value: 'py', label: "Previous Year" }
                                                ]

                                                const selectedItem = menuItems.find(item => item.value === selected)
                                                return selectedItem ? selectedItem.label : "Comparison"
                                            }}
                                            onChange={(e) => {
                                                setSelectedComparison(e.target.value)
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem value='py'>Previous Year</MenuItem>
                                            <MenuItem value="pp" disabled={presetValue === 60 || presetValue === 70}>Previous Period</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        {/* <InputLabel id="demo-simple-select-label">Group Data By</InputLabel> */}
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            // id="demo-simple-select"
                                            value={(presetValue === 20 || presetValue === 80) ? undefined : groupDataValue}
                                            onChange={(e) => { setGroupDataValue(e?.target?.value) }}
                                            // label="Group Data By"
                                            displayEmpty
                                            InputLabelProps={{ shrink: false }}
                                            disabled={presetValue === 20 || presetValue === 80}
                                            IconComponent={() => null}
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return 'GroupBy'
                                                }
                                                switch (selected) {
                                                    case 10:
                                                        return 'Week'
                                                    case 20:
                                                        return 'Month'
                                                    case 30:
                                                        return 'Day'
                                                    case 40:
                                                        return 'Quarter'
                                                    default:
                                                        return 'GroupBy'
                                                }
                                            }}
                                        >
                                            <MenuItem value={10} disabled={presetValue === 90 || presetValue === 100}>Week</MenuItem>
                                            <MenuItem value={20} disabled={presetValue === 90 || presetValue === 100 || presetValue === 30 || presetValue === 110 || presetValue === 40}>Month</MenuItem>
                                            <MenuItem value={30} disabled={presetValue === 50 || presetValue === 120 || presetValue === 60 || presetValue === 70}>Day</MenuItem>
                                            <MenuItem value={40} disabled={presetValue === 90 || presetValue === 100 || presetValue === 30 || presetValue === 110 || presetValue === 40 || presetValue === 50 || presetValue === 120}>Quarter</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        <Select
                                            value={selectedShop}
                                            displayEmpty
                                            InputLabelProps={{ shrink: false }}
                                            onChange={(e) => { setSelectedShop(e.target.value) }}
                                            IconComponent={() => null}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return `All Shops`
                                                }
                                                return selected
                                            }}
                                        >
                                            <MenuItem value="" >
                                                All
                                            </MenuItem>
                                            {shop.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        <Select
                                            value={selectedShipmentCountry}
                                            displayEmpty
                                            InputLabelProps={{ shrink: false }}
                                            onChange={(e) => { setSelectedShipmentCountry(e.target.value) }}
                                            IconComponent={() => null}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return `All Country`
                                                }
                                                return selected
                                            }}
                                        >
                                            <MenuItem value="" >
                                                All
                                            </MenuItem>
                                            {shipment.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        {/* <InputLabel id="demo-simple-select-label">Warehouse</InputLabel> */}
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            // id="demo-simple-select"
                                            value={selectedWarehouse}
                                            displayEmpty
                                            InputLabelProps={{ shrink: false }}
                                            // label="Warehouse"
                                            onChange={(e) => { setSelectedWarehouse(e.target.value) }}
                                            IconComponent={() => null}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return `All Warehouse`
                                                }
                                                return selected
                                            }}
                                        >
                                            <MenuItem value="" >
                                                All
                                            </MenuItem>
                                            {warehouse.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        <Select
                                            value={selectedShopType}
                                            displayEmpty
                                            onChange={(e) => setSelectedShopType(e.target.value)}
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return "All Shop Type"
                                                }
                                                const menuItems = [
                                                    { value: "All", label: "All" },
                                                    { value: "B2C", label: "B2C" },
                                                    { value: "B2B", label: "B2B" }
                                                ]
                                                const selectedItem = menuItems.find(item => item.value === selected)
                                                return selectedItem ? selectedItem.label : "Shop Type"
                                            }}
                                        >
                                            <MenuItem value="">All</MenuItem>
                                            <MenuItem value="B2C">B2C</MenuItem>
                                            <MenuItem value="B2B">B2B</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title={'Sku or Design'}>
                                        <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                            <TextField
                                                placeholder='Sku or Design'
                                                size='small'
                                                variant='outlined'
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                                value={searchSku}
                                                onChange={(e) => setSearchSku(e.target.value.trim())}
                                                input={<CustomisedInput />}
                                            // onChange={(e) => { setProductSku(e.target.value.trim()), setSkuPage(0) }}
                                            />
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        <TextField
                                            type='date'
                                            size='small'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            IconComponent={() => null}
                                            id='StartDate'
                                            value={fromDate}
                                            onChange={(e) => { setFromDate(e.target.value) }}
                                            disabled={(presetValue === 20 || presetValue === 30 || presetValue === 40 || presetValue === 50 || presetValue === 60 || presetValue === 70 || presetValue === 80)}
                                            required

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField
                                        type='date'
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        IconComponent={() => null}
                                        id='StartDate'
                                        value={toDate}
                                        onChange={(e) => { setToDate(e.target.value) }}
                                        min={fromDate}
                                        disabled={(presetValue === 20 || presetValue === 30 || presetValue === 40 || presetValue === 50 || presetValue === 60 || presetValue === 70) || !fromDate}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={1} >
                                    <FormControl sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        <Select
                                            value={selectedSkuFilter === "All" ? "Warehouse Discontinued Products" : selectedSkuFilter}
                                            displayEmpty
                                            InputLabelProps={{ shrink: false }}
                                            onChange={handleSkuFilterChange}
                                            IconComponent={() => null}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return `SKU Discontinued Filter`
                                                }
                                                return selected
                                            }}
                                        >
                                            <MenuItem value="" >All</MenuItem>
                                            {warehouse.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }} size='small' className='border-none'>
                                        <Select
                                            value={selectedCommerceType}
                                            displayEmpty
                                            InputLabelProps={{ shrink: false }}
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return "Commerce Type"
                                                }
                                                const menuItems = [
                                                    { value: 'ecomm', label: "Ecomm" },
                                                    { value: 'false', label: "Non Ecomm" }
                                                ]
                                                const selectedItem = menuItems.find(item => item.value === selected)
                                                return selectedItem ? selectedItem.label : "Commerce Type"
                                            }}
                                            onChange={(e) => {
                                                setSelectedCommerceType(e.target.value)
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem value="ecomm">Ecomm</MenuItem>
                                            <MenuItem value='false'>Non Ecomm</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip size={'2rem'} title={buttonLoading ? 'Please wait while we fetching the records' : ""}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleApplyFilter()}
                                            sx={{
                                                backgroundColor: '#151718',
                                                '&:hover': {
                                                    backgroundColor: '#151718',
                                                },
                                                textTransform: 'none',
                                                '@media (max-width: 600px)': {
                                                    fontSize: '0.8rem',
                                                },
                                            }}
                                        >
                                            {buttonLoading ? <CircularProgress size={20} style={{ color: 'white' }} /> : `Apply`}
                                        </Button>
                                    </Tooltip>

                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={resetFilters}
                                        sx={{
                                            backgroundColor: '#151718',
                                            '&:hover': {
                                                backgroundColor: '#151718',
                                            },
                                            textTransform: 'none',
                                            '@media (max-width: 600px)': {
                                                fontSize: '0.8rem',
                                            },
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                {showFilterMessage && (
                                    <Grid item xs={12}>
                                        <h6 style={{ color: 'red', marginTop: '10px' }}>Please select filters</h6>
                                    </Grid>
                                )}

                            </Grid>

                        </Box>
                    </div>

                    {revenueData.length && unitSoldData.length ? (!selectedShop && !selectedWarehouse && !selectedShipmentCountry && !fromDate && !toDate && (presetValue === 10)) ? ("") : (
                        <>
                            <Grid className='mt-4'>
                                <div className="common-card rounded-4 h-100 mt-4">
                                    <div className="common-card-content border-0">
                                        <Box sx={{ width: '100%', marginTop: '15px' }}>
                                            <Tabs value={graphValue} onChange={(event, newValue) => handleGraphChange('GRAPH', newValue)} >
                                                <Tab value={0} label={<Typography style={{ fontWeight: 'bold' }}>Sales Revenue</Typography>} />
                                                <Tab value={1} label={<Typography style={{ fontWeight: 'bold' }}>Unit Sold</Typography>} />
                                            </Tabs>
                                        </Box>
                                        {showSalesRevenue && (
                                            <>
                                                <Grid className='dash_four'>
                                                    <Grid className='pt-4'>
                                                        <span className='dash_twelve'>Total Revenue</span>
                                                    </Grid>
                                                    <Grid className='pt-4' style={{ paddingLeft: "5px" }}>
                                                        <IoIosInformationCircleOutline size={"2rem"} />
                                                    </Grid>
                                                </Grid>

                                                <Grid className='dash_four'>
                                                    <Grid>
                                                        <span className='dash_thirteen'>
                                                            <MdEuroSymbol size={"1.5rem"} />
                                                            {totalSales?.totals?.totalUntaxedTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            {selectedComparison && (
                                                                <>
                                                                    {percentageUntaxedTotalDifference > 0 ? (
                                                                        <FaArrowTrendUp
                                                                            className='ms-2'
                                                                            fontSize={'1.5rem'}
                                                                            color='#34C085'
                                                                            style={{ marginLeft: '20px', marginRight: '10px' }}
                                                                        />
                                                                    ) : (
                                                                        <FaArrowTrendDown
                                                                            className='ms-2'
                                                                            fontSize={'1.5rem'}
                                                                            color='red' // Change color for down arrow as needed
                                                                            style={{ marginLeft: '20px', marginRight: '10px' }}
                                                                        />
                                                                    )}
                                                                </>

                                                            )}

                                                        </span>
                                                        {selectedComparison && <span>{percentageUntaxedTotalDifference}% Year-Over-Year Growth Rate (YoY)</span>}
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box sx={{ width: '100%', height: 500, position: 'relative' }}>
                                                        <ResponsiveChartContainer
                                                            series={[
                                                                {
                                                                    type: 'line',
                                                                    data: revenueData.map(item => item.value),
                                                                    label: 'Current Year',
                                                                },
                                                                {
                                                                    type: 'line',
                                                                    data: selectedComparison ? revenueData.map(item => item.additionalValue) : [],
                                                                    label: selectedComparison === 'py' ? 'Previous Year' : 'Previous Period',
                                                                },
                                                            ]}
                                                            xAxis={[
                                                                {
                                                                    data: revenueData.map(item => item.argument),
                                                                    scaleType: 'point',
                                                                    id: 'x-axis-id',
                                                                },
                                                            ]}
                                                            yAxis={[{ scaleType: 'linear' }]}
                                                        >
                                                            <LinePlot />
                                                            <ChartsTooltip />
                                                            <ChartsXAxis position="bottom" axisId="x-axis-id" />
                                                            <ChartsYAxis position="left" />
                                                            <ChartsLegend />
                                                        </ResponsiveChartContainer>
                                                    </Box>
                                                </Grid>
                                            </>
                                        )}


                                        {!showSalesRevenue && (
                                            <>
                                                <Grid className='dash_four'>
                                                    <Grid className='pt-4'>
                                                        <span className='dash_twelve'>Unit Sold</span>
                                                    </Grid>
                                                    <Grid className='pt-4' style={{ paddingLeft: "5px" }}>
                                                        <IoIosInformationCircleOutline size={"2rem"} />
                                                    </Grid>
                                                </Grid>

                                                <Grid className='dash_four'>
                                                    <Grid>
                                                        <span className='dash_thirteen'>
                                                            {totalSales?.totals?.totalQuantity}
                                                            {selectedComparison && (
                                                                <>
                                                                    {percentageQuantityDifference > 0 ? (
                                                                        <FaArrowTrendUp
                                                                            className='ms-2'
                                                                            fontSize={'1.5rem'}
                                                                            color='#34C085'
                                                                            style={{ marginLeft: '20px', marginRight: '10px' }}
                                                                        />
                                                                    ) : (
                                                                        <FaArrowTrendDown
                                                                            className='ms-2'
                                                                            fontSize={'1.5rem'}
                                                                            color='red' // Change color for down arrow as needed
                                                                            style={{ marginLeft: '20px', marginRight: '10px' }}
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                        </span>
                                                        {selectedComparison && <span>{percentageQuantityDifference}% Year-Over-Year Growth Rate (YoY)</span>}
                                                    </Grid>
                                                </Grid>
                                                {/* <Grid item xs={12}>
                                                <LineChart
                                                    width={1400} // Adjust width as per your requirement
                                                    height={500} // Adjust height as per your requirement
                                                    series={[
                                                        { data: unitSoldData.map(item => item.value), label: 'Current Year' },
                                                        { data: selectedComparison ? unitSoldData.map(item => item.additionalValue) : [], label: `${selectedComparison === 'py' ? 'Previous Year' : 'Previous Period'}` }
                                                    ]}
                                                    xAxis={[{ scaleType: 'point', data: unitSoldData.map(item => item.argument) }]}
                                                    yAxis={[{ scaleType: 'linear' }]}
                                                />
                                            </Grid> */}
                                                <Grid item xs={12}>
                                                    <Box sx={{ width: '100%', height: 500, position: 'relative' }}>
                                                        <ResponsiveChartContainer
                                                            series={[
                                                                {
                                                                    type: 'line',
                                                                    data: unitSoldData.map(item => item.value),
                                                                    label: 'Current Year',
                                                                },
                                                                {
                                                                    type: 'line',
                                                                    data: selectedComparison ? unitSoldData.map(item => item.additionalValue) : [],
                                                                    label: selectedComparison === 'py' ? 'Previous Year' : 'Previous Period',
                                                                },
                                                            ]}
                                                            xAxis={[
                                                                {
                                                                    data: unitSoldData.map(item => item.argument),
                                                                    scaleType: 'point',
                                                                    id: 'x-axis-id',
                                                                },
                                                            ]}
                                                            yAxis={[{ scaleType: 'linear' }]}
                                                        >
                                                            <LinePlot />
                                                            <ChartsTooltip />
                                                            <ChartsXAxis position="bottom" axisId="x-axis-id" />
                                                            <ChartsYAxis position="left" />
                                                            <ChartsLegend />
                                                        </ResponsiveChartContainer>
                                                    </Box>
                                                </Grid>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Grid>

                            {/* Cards Section */}
                            <Grid>
                                <Grid container spacing={4}>
                                    {/* Shop Type Card */}
                                    {salesData && quantityData && salesData.length !== 0 && quantityData.length !== 0 && (
                                        <Grid item xs={12} md={4} className='mt-4'>
                                            <div className="common-card rounded-4 h-100"> {/* Adjusted height for better spacing */}
                                                <div className="common-card-content border-0">
                                                    <div className="card-header">
                                                        <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', paddingBottom: '20px' }}>
                                                            Shop Type
                                                        </Typography>
                                                    </div>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                        {/* Sales Pie Chart */}
                                                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                                                Sales
                                                            </Typography>
                                                            <Box sx={{ width: '100%', height: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <PieChart
                                                                    series={[
                                                                        {
                                                                            data: addColorsToData(salesData),
                                                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                                                        },
                                                                    ]}
                                                                // height={250}
                                                                // slotProps={{
                                                                //     legend: { hidden: true },
                                                                // }}
                                                                />
                                                            </Box>
                                                        </Box>

                                                        {/* Quantity Pie Chart */}
                                                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                                                Quantity
                                                            </Typography>
                                                            <Box sx={{ width: '100%', height: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <PieChart
                                                                    series={[
                                                                        {
                                                                            innerRadius: 80,
                                                                            data: addColorsToData(quantityData),
                                                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                                                        },
                                                                    ]}
                                                                // height={250}
                                                                // slotProps={{
                                                                //     legend: { hidden: true },
                                                                // }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}

                                    {/* Warehouse Card */}
                                    {salesWarehouseData && quantityWarehouseData && salesWarehouseData.length !== 0 && quantityWarehouseData.length !== 0 && (
                                        <Grid item xs={12} md={8} className='mt-4'>
                                            <div className="common-card rounded-4 h-100">
                                                <div className="common-card-content border-0">
                                                    <div className="card-header">
                                                        <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', paddingBottom: '20px' }}>
                                                            Warehouse
                                                        </Typography>
                                                    </div>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                        {/* Sales Pie Chart */}
                                                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', fontSize: '18px', paddingBottom: '10px' }}>
                                                                Sales
                                                            </Typography>
                                                            <Box sx={{ width: '100%', height: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <PieChart
                                                                    series={[
                                                                        {
                                                                            data: addColorsToData(salesWarehouseData),
                                                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                                                        },
                                                                    ]}
                                                                // height={250}
                                                                // slotProps={{
                                                                //     legend: { hidden: true },
                                                                // }}
                                                                />
                                                            </Box>
                                                        </Box>

                                                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', fontSize: '18px', paddingBottom: '10px' }}>
                                                                Quantity
                                                            </Typography>
                                                            <Box sx={{ width: '100%', height: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <PieChart
                                                                    series={[
                                                                        {
                                                                            innerRadius: 80,
                                                                            data: addColorsToData(quantityWarehouseData),
                                                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                                                        },
                                                                    ]}
                                                                // height={250}
                                                                // slotProps={{
                                                                //     legend: { hidden: true },
                                                                // }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                                {/* Shop Card */}
                                {salesShopData && quantityShopData && salesShopData.length !== 0 && quantityShopData.length !== 0 && (
                                    <Grid item xs={12} md={12} className='mt-4'>
                                        <div className="common-card rounded-4 h-100" style={{ height: '300px' }}>
                                            <div className="common-card-content border-0">
                                                <div className="card-header" style={{ paddingBottom: '20px' }}>
                                                    <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold' }}>
                                                        Shop
                                                    </Typography>
                                                </div>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', fontSize: '18px', paddingBottom: '15px' }}>
                                                                Sales
                                                            </Typography>
                                                            <Box sx={{ width: '100%', height: 300, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <PieChart
                                                                    series={[
                                                                        {
                                                                            outerRadius: 120,
                                                                            data: addColorsToData(salesShopData),
                                                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                                                        },
                                                                    ]}
                                                                    height={350}
                                                                // slotProps={{
                                                                //     legend: {
                                                                //         direction: 'column',
                                                                //         itemGap: 10,
                                                                //         position: {
                                                                //             vertical: 'middle',
                                                                //             horizontal: 'left',
                                                                //         },
                                                                //         sx: {
                                                                //             width: '150px', // Adjust width for spacing
                                                                //         },
                                                                //     },
                                                                // }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', fontSize: '18px', paddingBottom: '15px' }}>
                                                                Quantity
                                                            </Typography>
                                                            <Box sx={{ width: '100%', height: 300, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <PieChart
                                                                    series={[
                                                                        {
                                                                            innerRadius: 80,
                                                                            outerRadius: 120,
                                                                            data: addColorsToData(quantityShopData),
                                                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                                                        },
                                                                    ]}
                                                                    height={350}
                                                                // slotProps={{
                                                                //     legend: {
                                                                //         direction: 'column',
                                                                //         itemGap: 10,
                                                                //         position: {
                                                                //             vertical: 'middle',
                                                                //             horizontal: 'left',
                                                                //         },
                                                                //         sx: {
                                                                //             width: '150px', // Adjust width for spacing
                                                                //         },
                                                                //     },
                                                                // }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                )}

                                {/* Shipment Country Card */}
                                {salesCountryData && quantityCountryData && salesCountryData.length !== 0 && quantityCountryData.length !== 0 && (
                                    <Grid item xs={12} md={12} className='mt-4'>
                                        <div className="common-card rounded-4 h-100" style={{ height: '300px' }}>
                                            <div className="common-card-content border-0">
                                                <div className="card-header" style={{ paddingBottom: '20px' }}>
                                                    <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold' }}>
                                                        Shipment Country
                                                    </Typography>
                                                </div>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', fontSize: '18px', paddingBottom: '15px' }}>
                                                                Sales
                                                            </Typography>
                                                            <Box sx={{ width: '100%', height: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <PieChart
                                                                    series={[
                                                                        {
                                                                            data: addColorsToData(salesCountryData),
                                                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                                                        },
                                                                    ]}
                                                                // height={250}
                                                                // slotProps={{
                                                                //     legend: { hidden: true },
                                                                // }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                                                            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', fontSize: '18px', paddingBottom: '15px' }}>
                                                                Quantity
                                                            </Typography>
                                                            <Box sx={{ width: '100%', height: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                <PieChart
                                                                    series={[
                                                                        {
                                                                            innerRadius: 80,
                                                                            data: addColorsToData(quantityCountryData),
                                                                            highlightScope: { highlighted: 'item', faded: 'global' },
                                                                        },
                                                                    ]}
                                                                // height={250}
                                                                // slotProps={{
                                                                //     legend: { hidden: true },
                                                                // }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    ) : ("")
                    }


                    {total ? (!selectedShop && !selectedWarehouse && !selectedShipmentCountry && !fromDate && !toDate && (presetValue === 10)) ? null : (
                        <>
                            <Grid container spacing={2} className='mt-2'>
                                <Grid item xs={12}>
                                    <div className="common-card rounded-4 h-100">
                                        <div className="common-card-content border-0">
                                            <Box sx={{ width: '100%', marginTop: '15px', marginBottom: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Tabs value={viewType === 'product' ? 0 : 1} onChange={handleGraphChangeTab} >
                                                    <Tab label={<Typography style={{ fontWeight: 'bold' }}>Product Performance</Typography>} />
                                                    <Tab label={<Typography style={{ fontWeight: 'bold' }}>Design Performance</Typography>} />
                                                </Tabs>
                                            </Box>

                                            <SalesDashboardTable
                                                handleSort={handleSort}
                                                getColumnHeader={getColumnHeader}
                                                getIconStyle={getIconStyle}
                                                loadedData={loadedData}
                                                isLoading={isLoadings}
                                                tableContainerRef={tableContainerRef}
                                                viewType={viewType}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    ) : ("")
                    }
                    {/* </div> */}
                </div >
            </section >
            <ModalDialog
                open={openModal}
                fullWidth
                isDivider='true'
                title={<div>
                    <h5 className='fw-semibold'>INFORMATION </h5>
                </div>}
                content={<p>Sales Data import initiated!! Kindly wait a few minutes , this will be updated shortly.</p>}
                handleClose={() => setOpenModal(false)}
            />
        </>
    )
}

export default Index