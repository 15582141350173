import React from 'react'
import PropTypes from 'prop-types'
import { DialogTitle as MUIDialogTitle, IconButton } from '@mui/material'
import { MdClose } from 'react-icons/md'


const DialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <MUIDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose
        ? (<>
         
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <MdClose />
          </IconButton>
          </>
          )
        : null}
    </MUIDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export default DialogTitle
