import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import Icon from 'assets/images/login-logo-two.svg'
import './analytics.css'
import ShopDataComponent from './ShopDataComponent'
import SkuDataComponent from './SkuDataComponent'
import ShopSkuDataComponent from './ShopSkuDataComponent'
import ShopFilter from './shopFilter'
import SkuFilter from './skuFilter'
import ShopSkuFilter from './shopSkuFilter'
import { camelCaseToTitleCase } from 'shared/util/helper'
import CommonBox from 'pages/analytics/common/CommonBox'

const InvoiceTemplate = (props) => {
    const { shops, shopCountryData, clickedRows, handleClick, selectedYear, yearSelectStartDate, yearSelectEndDate, selectedShipmentCountry, selectedOption, searchShop, weekSales, monthSales,
        yearSales, sales, selectedPdfType, shopsSku, skuSelectedOption, searchSku, selectedShop, selectedShipmentCountrySku, selectedPercent, skuYearSelectStartDate, skuYearSelectEndDate, } = props

    const formatDecimal = (value) => {
        if (typeof value === 'number') {
            return value.toFixed(2)
        }
        return value
    }

    const isShopCountryDataAvailable = (shop) => {
        return shopCountryData?.period_data?.some((countryData) => countryData?.shop === shop)
    }
    const yearDifference = sales.year?.totalUntaxedTotal - sales.lyear?.totalUntaxedTotal
    const yearUnitDifference = sales.year?.totalQuantity - sales.lyear?.totalQuantity
    const monthDifference = sales.month?.totalUntaxedTotal - sales.lmonth?.totalUntaxedTotal
    const monthUnitDifference = sales.month?.totalQuantity - sales.lmonth?.totalQuantity
    const weekDifference = sales.week?.totalUntaxedTotal - sales.lweek?.totalUntaxedTotal
    const weekUnitDifference = sales.week?.totalQuantity - sales.lweek?.totalQuantity
    const monthCurrentDifference = sales.month?.totalUntaxedTotal - sales.prevMonthData?.totalUntaxedTotal
    const weekCurrentDifference = sales.week?.totalUntaxedTotal - sales.lastMonthWeekData?.totalUntaxedTotal
    const monthCurrentUnitDifference = sales.month?.totalQuantity - sales.prevMonthData?.totalQuantity
    const weekCurrentUnitDifference = sales.week?.totalQuantity - sales.lastMonthWeekData?.totalQuantity
    const commonBoxData = [
        { title: 'WTD Sales', amount: weekSales, difference: selectedYear === 'lastYear' ? weekDifference : weekCurrentDifference, isSales: true, disabled: false, },
        { title: 'MTD Sales', amount: monthSales, difference: selectedYear === 'lastYear' ? monthDifference : monthCurrentDifference, isSales: true, disabled: false, },
        { title: 'YTD Sales', amount: yearSales, difference: yearDifference, isSales: true, disabled: selectedYear === 'currentYear', },
        { title: 'WTD Units', amount: sales.week?.totalQuantity, difference: selectedYear === 'lastYear' ? weekUnitDifference : weekCurrentUnitDifference, isSales: false, disabled: false, },
        { title: 'MTD Units', amount: sales.month?.totalQuantity, difference: selectedYear === 'lastYear' ? monthUnitDifference : monthCurrentUnitDifference, isSales: false, disabled: false, },
        { title: 'YTD Units', amount: sales.year?.totalQuantity, difference: yearUnitDifference, isSales: false, disabled: selectedYear === 'currentYear', }
    ]

    return (
        <>
            <div className={`pdf-page size-a4`}>
                <Grid container direction='row' justifyContent='space-between' pt={1}>
                    <Grid item md={6} pl={2} pb={3}>
                        <Grid item md={8} pl={2} pt={3}>
                            <img src={Icon} width='20%' />
                        </Grid>
                    </Grid>
                    <Grid item md={6} pl={1} pb={3} textAlign='right' pr={3} pt={3}>
                        <Grid item md={8} pl={1} pt={3}>
                            <span className='heading' style={{ fontWeight: 'bold' }}>Sales Data Type</span>
                        </Grid>
                        <Grid item md={8} pl={2}>
                            <span className='text'>{camelCaseToTitleCase(selectedPdfType)}</span>
                        </Grid>
                    </Grid>
                </Grid>

                {selectedPdfType === 'shopData' ? (
                    <ShopFilter
                        selectedOption={selectedOption}
                        selectedYear={selectedYear}
                        selectedShipmentCountry={selectedShipmentCountry}
                        yearSelectStartDate={yearSelectStartDate}
                        yearSelectEndDate={yearSelectEndDate}
                        searchShop={searchShop}
                    />
                ) : selectedPdfType === 'skuData' ? (
                    <SkuFilter
                        selectedYear={selectedYear}
                        skuSelectedOption={skuSelectedOption}
                        searchSku={searchSku}
                        selectedShop={selectedShop}
                        selectedShipmentCountrySku={selectedShipmentCountrySku}
                        selectedPercent={selectedPercent}
                        skuYearSelectStartDate={skuYearSelectStartDate}
                        skuYearSelectEndDate={skuYearSelectEndDate}
                    />
                ) : selectedPdfType === 'allData' ? (
                    < ShopSkuFilter
                        selectedOption={selectedOption}
                        selectedYear={selectedYear}
                        selectedShipmentCountry={selectedShipmentCountry}
                        yearSelectStartDate={yearSelectStartDate}
                        yearSelectEndDate={yearSelectEndDate}
                        searchShop={searchShop}
                        skuSelectedOption={skuSelectedOption}
                        searchSku={searchSku}
                        selectedShop={selectedShop}
                        selectedShipmentCountrySku={selectedShipmentCountrySku}
                        selectedPercent={selectedPercent}
                        skuYearSelectStartDate={skuYearSelectStartDate}
                        skuYearSelectEndDate={skuYearSelectEndDate}
                    />
                ) : ''}

                <Grid container>
                    <Grid item xs={12} lg={12} md={12} sm={12} sx={{ marginBottom: '20px' }}>
                        <Grid container spacing={2} alignItems='center'>
                            {/* DataBoxes */}
                            {Array.isArray(commonBoxData) && commonBoxData.length ? commonBoxData.map((res, index) => {
                                return <Grid item xs={4} key={index}>
                                    <CommonBox
                                        title={res?.title}
                                        amount={res?.amount}
                                        difference={res?.difference}
                                        isSales={res?.isSales}
                                    />
                                </Grid>
                            }) : ("")}
                        </Grid>
                    </Grid>
                </Grid>

                {selectedPdfType === 'shopData' ? (
                    <ShopDataComponent
                        shops={shops}
                        shopCountryData={shopCountryData}
                        handleClick={handleClick}
                        clickedRows={clickedRows}
                        isShopCountryDataAvailable={isShopCountryDataAvailable}
                        formatDecimal={formatDecimal}
                    />
                ) : selectedPdfType === 'skuData' ? (
                    <SkuDataComponent
                        shopsSku={shopsSku}
                        formatDecimal={formatDecimal}
                    />
                ) : selectedPdfType === 'allData' ? (
                    < ShopSkuDataComponent
                        shops={shops}
                        shopCountryData={shopCountryData}
                        handleClick={handleClick}
                        clickedRows={clickedRows}
                        isShopCountryDataAvailable={isShopCountryDataAvailable}
                        formatDecimal={formatDecimal}
                        shopsSku={shopsSku}
                    />
                ) : ''}
            </div >
        </>
    )
}

InvoiceTemplate.propTypes = {
    shops: PropTypes.array.isRequired,
    shopCountryData: PropTypes.object.isRequired,
    clickedRows: PropTypes.array.isRequired,
    handleClick: PropTypes.func.isRequired,
    selectedYear: PropTypes.string.isRequired,
    yearSelectStartDate: PropTypes.string.isRequired,
    yearSelectEndDate: PropTypes.string.isRequired,
    selectedShipmentCountry: PropTypes.string.isRequired,
    selectedOption: PropTypes.string.isRequired,
    searchShop: PropTypes.string.isRequired,
    weekSales: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    monthSales: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    yearSales: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sales: PropTypes.object.isRequired,
    selectedPdfType: PropTypes.string.isRequired,
    shopsSku: PropTypes.array.isRequired,
    skuSelectedOption: PropTypes.string.isRequired,
    searchSku: PropTypes.string.isRequired,
    selectedShop: PropTypes.string.isRequired,
    selectedShipmentCountrySku: PropTypes.string.isRequired,
    selectedPercent: PropTypes.string.isRequired,
    skuYearSelectStartDate: PropTypes.string.isRequired,
    skuYearSelectEndDate: PropTypes.string.isRequired
}
export default InvoiceTemplate
