import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CBadge, CTooltip } from '@coreui/react'
import { isAuthorized } from 'shared/util/helper'
import { UserContext } from 'context/AuthContext/UserContext'

export const AppSidebarNav = ({ items }) => {


  const contextObj = React.useContext(UserContext)

  const navLink = (name, icon, badge, userType) => {
    const tooltipContent = name
    if ((userType === 1 || userType === 4) && contextObj.userData.user.pomUserType === 1) {
      return (
        <CTooltip content={tooltipContent}>
          <div className="d-flex align-items-center">
            {icon && icon}
            {name && name}
            {badge && (
              <CBadge color={badge.color} className='ms-auto'>
                {badge.text}
              </CBadge>
            )}
          </div>
        </CTooltip>
      )
    }
    else if ((userType === 2 || userType === 4) && contextObj.userData.user.pomUserType === 2) {
      return (
        <CTooltip content={tooltipContent}>
          <div className="d-flex align-items-center">
            {icon && icon}
            {name && name}
            {badge && (
              <CBadge color={badge.color} className='ms-auto'>
                {badge.text}
              </CBadge>
            )}
          </div>
        </CTooltip>
      )
    } else if (userType === 3 || contextObj.userData.user.pomUserType === 3) {
      return (
        <CTooltip content={tooltipContent}>
          <div className="d-flex align-items-center">
            {icon && icon}
            {name && name}
            {badge && (
              <CBadge color={badge.color} className='ms-auto'>
                {badge.text}
              </CBadge>
            )}
          </div>
        </CTooltip>
      )
    } else if (userType === 4 || contextObj.userData.user.pomUserType === 4) {
      return (
        <CTooltip content={tooltipContent}>
          <div className="d-flex align-items-center">
            {icon && icon}
            {name && name}
            {badge && (
              <CBadge color={badge.color} className='ms-auto'>
                {badge.text}
              </CBadge>
            )}
          </div>
        </CTooltip>
      )
    }
  }

  const navItem = (item, index) => {
    const { userType, component, name, badge, icon, ...rest } = item
    const Component = component
    if ((userType === 1 || userType === 4) && contextObj?.userData?.user?.pomUserType === 1) {
      return (

        <Component
          {...(rest.to &&
            !rest.items && {
            component: NavLink,
            activeclassname: 'active'
          })}
          key={index}
          {...rest}
        >

          {navLink(name, icon, badge, userType)}
        </Component>
      )
    }
    else {
      if ((userType === 2 || userType === 4) && contextObj.userData.user.pomUserType === 2) {
        return (

          <Component
            {...(rest.to &&
              !rest.items && {
              component: NavLink,
              activeclassname: 'active'
            })}
            key={index}
            {...rest}
          >

            {navLink(name, icon, badge, userType)}
          </Component>
        )
      }
      else if ((userType === 3 || userType === 4) && contextObj.userData.user.pomUserType === 3) {
        return (
          <Component
            {...(rest.to && !rest.items && {
              component: NavLink,
              activeclassname: 'active'
            })}
            key={index}
            {...rest}
          >
            {navLink(name, icon, badge, userType)}
          </Component>
        )
      }
      else if ((userType === 4 || userType === 5) && contextObj.userData.user.pomUserType === 4) {
        return (
          <Component
            {...(rest.to && !rest.items && {
              component: NavLink,
              activeclassname: 'active'
            })}
            key={index}
            {...rest}
          >
            {navLink(name, icon, badge, userType)}
          </Component>
        )
      }
    }
  }
  return (
    <>
      {items &&
        items.map((item, index) =>
          isAuthorized(contextObj.userData?.user?.role, item.role ?? contextObj.userData?.user?.role) &&
            isAuthorized(contextObj.userData?.user?.pricingPlan, item.pricing)
            ? navItem(item, index)
            : ''
        )}
    </>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired
}
