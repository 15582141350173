import * as React from 'react'
import { MenuItem, Select, FormControl, FormHelperText, Tooltip, Grid, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { FcAlphabeticalSortingAz, FcAlphabeticalSortingZa } from 'react-icons/fc'
import CustomisedInput from 'components/formElements/CustomisedInput'
import { UserContext } from 'context/AuthContext/UserContext'
import { useNavigate } from 'react-router-dom'

const SortDropdown = (props) => {
  const navigate = useNavigate()
  const contextObj = React.useContext(UserContext)
  const handleChange = (event) => {
    props.setSortOn(event.target.value)
  }

  const handleSkuFilterChange = async (event, type) => {
    const filterValue = event.target.value
    await props.setpageNumber(0)
    if (type === "ProductType") {
      await props.setProductType(filterValue)
    }
    else {
      await props.setSkuFilter(filterValue)
    }
  }

  return (
    <>
      <Grid container direction='row' justifyContent='flex-start' alignItems='flex-end' style={{ paddingLeft: '10px' }}>
        <Tooltip title='Sort On Parameter'>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <FormHelperText className='remove-margin'>Sort By</FormHelperText>
              <Select
                data-testid='select'
                id='catalogue-sort'
                value={props.sortOn}
                onChange={handleChange}
                defaultValue='createdOn'
                autoWidth
                label='Sort'
                inputProps={{ 'aria-label': 'Without label' }}
                input={<CustomisedInput />}
              >
                <MenuItem value='' data-testid='menu-item-1'>
                  <em>Select Sort</em>
                </MenuItem>
                {(props?.productView === 1 ? [
                  { name: 'design', label: 'Product Design' },
                  { name: 'createdOn', label: 'Create Date' }
                ] : props?.items
                )?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.name}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Tooltip>
        <Tooltip title={props.sortOrder === 1 ? 'Sort in Descending Order' : 'Sort in Ascending Order'}>
          <Grid item sx={{ marginBottom: '0.6rem' }}>
            {props.sortOrder === 1 ? (
              <Button
                variant='outlined'
                sx={{
                  border: '1px solid #ced4da'
                }}
                onClick={() => props.setSortOrder(-1)}
              >
                <FcAlphabeticalSortingAz size='2rem' />
              </Button>
            ) : (
              <Button
                variant='outlined'
                sx={{
                  border: '1px solid #ced4da'
                }}
                onClick={() => props.setSortOrder(1)}
              >
                <FcAlphabeticalSortingZa size='2rem' />
              </Button>
            )}
          </Grid>
        </Tooltip>
        {contextObj?.userData?.user?.pomUserType === 2 ? ("") : (<Grid item>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <FormHelperText className='remove-margin'>Product View By</FormHelperText>
            <Select
              data-testid='select'
              id='catalogue-sort'
              value={props.productView}
              onChange={(e) => {
                props.setSearchProductValue('')
                props.setProductView(e.target.value)
              }}
              defaultValue='createdOn'
              autoWidth
              label='Sort'
              inputProps={{ 'aria-label': 'Without label' }}
              input={<CustomisedInput />}
            >
              <MenuItem value={0} data-testid='menu-item-1'>
                <em>Select Sort</em>
              </MenuItem>
              <MenuItem onClick={() => navigate(`/products`)} key={1} value={1}>
                By Design
              </MenuItem>
              <MenuItem onClick={() => navigate(`/products?product_type=PRODUCT`)} key={0} value={0}>
                By Product
              </MenuItem>
            </Select>
          </FormControl>
          {props?.productView === 0 ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 260, maxWidth: 260 }}>
                  <FormHelperText className='remove-margin mb-2'>DisContinued Warehosue Product</FormHelperText>
                  <Select
                    data-testid='select'
                    id='sku-filter'
                    value={props.skuFilter}
                    onChange={(e) => handleSkuFilterChange(e)}
                    label='SKU Filter'
                    inputProps={{ 'aria-label': 'Without label' }}
                    input={<CustomisedInput />}
                  >
                    <MenuItem value="All">All Product</MenuItem>
                    {/* <MenuItem value="continued">Active Product</MenuItem>
                    <MenuItem value="discontinued">Dis-Continued Product</MenuItem> */}
                    {props.warehouseApiData?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 260 }}>
                  <FormHelperText className='remove-margin'>Product Filter</FormHelperText>
                  <Select
                    data-testid='select'
                    id='sku-filter'
                    value={props.productType}
                    onChange={(e) => handleSkuFilterChange(e, 'ProductType')}
                    label='Product Filter'
                    inputProps={{ 'aria-label': 'Without label' }}
                    input={<CustomisedInput />}
                  >
                    <MenuItem value="Published">Published</MenuItem>
                    <MenuItem value="Draft">Draft</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>) : ("")}
        </Grid>)}
      </Grid>
    </>
  )
}

SortDropdown.propTypes = {
  sortOn: PropTypes.string,
  setSortOn: PropTypes.func,
  setSortOrder: PropTypes.func,
  sortOrder: PropTypes.number,
  warehouseApiData: PropTypes.array,
  items: PropTypes.array,
  setProductView: PropTypes.func,
  productView: PropTypes.number,
  setSearchProductValue: PropTypes.func,
  skuFilter: PropTypes.string,
  setSkuFilter: PropTypes.func,
  productType: PropTypes.string,
  setProductType: PropTypes.func,
  setpageNumber: PropTypes.func,
}

export default SortDropdown
