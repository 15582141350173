import React from 'react'
import './View.css'
import PropTypes from 'prop-types'

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

const Carousel = (props) => {
  const {
    images,
    handleLeftClick,
    handleRightClick,
    carouselItemsRef,
    handleSelectedImageChange,
    selectedImageIndex
  } = props

  return (
    <div className='carousel' data-testid='carousel-render'>
      <div className='carousel__images'>
        {images !== undefined &&
          images?.map((image, index) => (
            <div
              onClick={() => handleSelectedImageChange(index)}
              style={{ backgroundImage: `url(${image})` }}
              key={index}
              className={`carousel__image ${
                { selectedImageIndex } === index && 'carousel__image-selected'
              }`}
              ref={(el) => (carouselItemsRef.current[index] = el)}
              aria-hidden='true'
            />
          ))}
      </div>
      {/* Buttons for left and right carousel */}
      <AiOutlineLeft role='button' aria-label='leftBtn' className='carousel__button carousel__button-left' onClick={handleLeftClick} />

      <AiOutlineRight
        className='carousel__button carousel__button-right'
        onClick={handleRightClick}
      />
    </div>
  )
}
Carousel.propTypes = {
  images: PropTypes.array,
  handleLeftClick: PropTypes.func,
  handleRightClick: PropTypes.func,
  carouselItemsRef: PropTypes.object,
  handleSelectedImageChange: PropTypes.func,
  selectedImageIndex: PropTypes.number
}

export default Carousel
