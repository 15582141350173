import { CCol, CRow } from '@coreui/react'
import React from 'react'
import PoHeader from './PoHeader'
import PoSku from './PoSku'
import ResultPo from './ResultPo'
import SkuTable from './SkuTable'
import { usePoHooks } from './usePoHooks'
import { LoadingButton } from '@mui/lab'
import { HiViewGridAdd } from 'react-icons/hi'
import { useParams } from 'react-router-dom'
import { AppBreadcrumb } from 'sections/layout/dashboard'

function Index() {
  const param = useParams()
  let breadCrumbPath = [
    { name: 'Purchase Order', url: `/purchaseorders`, active: 'not-allowed' },
    {
      name: `${param.poNumber ? `Purchase Order Update` : `Purchase Order Create`}`,
      url: `${param?.poNumber ? `/purchaseOrder/edit/${param.poNumber}` : `/purchaseOrder/create`}`,
      active: 'not-allowed'
    }
  ]
  const {
    loading,
    values,
    handleChange,
    handleAddProduct,
    handlePoSubmit,
    validated,
    product,
    vendors,
    success,
    skuValue,
    setSkuValue,
    vendorProducts,
    error,
    EditPurchaseOrder,
    setVendorProducts,
    vendorsAllProducts,
    setVendorsAllProducts,
    warehouseName,
    PublishPurchaseOrder
  } = usePoHooks()

  return (
    <>
      <div className='page-spacing'>
        <div className='container-fluid'>
          {success ? (
            <ResultPo error={error} />
          ) : (
            <>
              <CRow className='ms-2 mt-5 mb-2'>
                <AppBreadcrumb pathNameDeclare={breadCrumbPath} />
                <CCol className='d-flex justify-content-between'>
                  <p className='heading-large display-6 mb-1 text-start'>Purchase Order</p>
                  <LoadingButton
                    variant='outlined'
                    loading={loading}
                    disabled={!skuValue.length > 0 || values?.isPublished === true}
                    onClick={PublishPurchaseOrder}
                  >
                    Publish
                    <HiViewGridAdd className='ms-3' size='1.5rem' />
                  </LoadingButton>
                </CCol>
              </CRow>
              <PoHeader
                values={values}
                handleChange={handleChange}
                handleSubmit={handlePoSubmit}
                validated={validated}
                vendors={vendors}
                warehouseName={warehouseName}
              />
              <PoSku
                vendorsAllProducts={vendorsAllProducts}
                setVendorsAllProducts={setVendorsAllProducts}
                values={product}
                handleChange={handleAddProduct}
                validated={validated}
                supplier={values.supplier}
                setSkuValue={setSkuValue}
                skuValue={skuValue}
              />
              <SkuTable
                Products={values.Products}
                skuValue={skuValue}
                vendors={vendors}
                setSkuValue={setSkuValue}
                vendorProducts={vendorProducts}
                setVendorProducts={setVendorProducts}
                vendorsAllProducts={vendorsAllProducts}
                setVendorsAllProducts={setVendorsAllProducts}
              />

              <CRow className='mt-5 mb-2'>
                <CCol className='mb-3 mr-4 me-4 d-flex justify-content-end'>
                  <LoadingButton
                    variant='outlined'
                    loading={loading}
                    disabled={!skuValue.length > 0}
                    onClick={param.poNumber ? EditPurchaseOrder : handlePoSubmit}
                  >
                    Save
                    <HiViewGridAdd className='ms-3' size='1.5rem' />
                  </LoadingButton>
                </CCol>
              </CRow>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Index
