import PropTypes from 'prop-types'
import React, { useState } from 'react'
import MessageList from './messages/MessageList'
import UserInput from 'components/chat/chatBox/UserInput'
import Header from './Header'

const ChatWindow = (props) => {
  const { queryNessage, setQueryMessage, showQuery, setShowQuery, chatType } = props

  const classList = ['sc-chat-window', props.isOpen ? 'opened' : 'closed']
  const [image, setImage] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  return (
    <div className={classList.join(' ')}>
      <Header
        teamName='POM'
        imageUrl={props.agentProfile.imageUrl}
        onClose={props.onClose}
        showQuery={showQuery}
        setShowQuery={setShowQuery}
        chatType={chatType}
        
      />
      <MessageList
        imageUrl={props.agentProfile.imageUrl}
        userId={props.userId}
        poId={props.poId}
        messages={props.messages}
        image={image}
        isLoading={isLoading}
        queryNessage={queryNessage}
        setQueryMessage={setQueryMessage}
        showQuery={showQuery}
        setShowQuery={setShowQuery}
        onSubmit={props.sendMessage}
        chatType={chatType}
      />

      {chatType !== 'order' && !showQuery ? (
        <UserInput
          onSubmit={props.sendMessage}
          isDisabled={props.isDisabled}
          userId={props.userId}
          poId={props.poId}
          poNumber={props.poNumber}
          image={image}
          setImage={setImage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        ''
      )}
    </div>
  )
}

ChatWindow.propTypes = {
  agentProfile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUserInputSubmit: PropTypes.func.isRequired,
  messageList: PropTypes.array,
  isDisabled: PropTypes.bool,
  userId: PropTypes.string,
  poId: PropTypes.string,
  sendMessage: PropTypes.func,
  messages: PropTypes.array,
  poNumber: PropTypes.string,
  queryNessage: PropTypes.string,
  setQueryMessage: PropTypes.func,
  showQuery: PropTypes.bool,
  setShowQuery: PropTypes.func,
  chatType: PropTypes.string
}

export default ChatWindow
