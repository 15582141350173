import React from 'react'
const CatalogueList = React.lazy(() => import('pages/catalogues/list/List'))
import AddPO from 'pages/purchaseOrder/create'
import Create from 'pages/orders/Create'
import AddP from 'pages/projects/list/List'
import PurchaseOrderDetails from 'pages/purchaseOrderDrtails/PurchaseOrderDetails'
import ViewVendorDetails from 'pages/account/company/ViewVendorDetails'
import ProjectsDetails from 'pages/projectsdetails/ProjectsDetails'
import ProjectHistory from 'pages/projectsdetails/ProjectHistory'
// import Index from 'pages/salesDashboard/Index'
// import socketIO from 'socket.io-client'
// import Messages from 'components/chat/fullPage'
// import Orders from 'pages/orders/Orders'
// import OrderListDetails from 'pages/orders/OrderListdetails'
const ProductsList = React.lazy(() => import('pages/products/view/ViewList'))
const AddProduct = React.lazy(() => import('pages/products/create'))
const Dashboard = React.lazy(() => import('pages/dashboard/Dashboard'))
const ProjectMenu = React.lazy(() => import('pages/projects/projectlist/List'))
const Delivery = React.lazy(() => import('pages/purchaseOrder/delivery/Delivery'))
const ProjectDelivery = React.lazy(() => import('pages/projects/delivery/Delivery'))

const SkuDelivery = React.lazy(() => import('pages/purchaseOrder/delivery/SkuDelivery'))
const PoDelivery = React.lazy(() => import('pages/projects/delivery/PoDelivery'))

const AccountMenu = React.lazy(() => import('pages/account/Menu'))
const Profile = React.lazy(() => import('pages/account/Profile'))
const Security = React.lazy(() => import('pages/account/Security'))
const ManageWarehouse = React.lazy(() => import('pages/account/company/warehouse'))
const CreateWareHouse = React.lazy(() => import('pages/account/company/warehouse/CreateWarehouse'))
const CompanyMenu = React.lazy(() => import('pages/account/company/Menu'))
const Details = React.lazy(() => import('pages/account/company/Details'))
const ManageUsers = React.lazy(() => import('pages/account/company/ManageUsers'))
const ManageRetailUsers = React.lazy(() => import('pages/account/company/RetailUsers'))
const ManageRetailShop = React.lazy(() => import('pages/account/company/ManageRetailShop'))
const ProductView = React.lazy(() => import('pages/products/view/ProductView'))
const StocksView = React.lazy(() => import('pages/stocks/StocksView'))
const PaymentMenu = React.lazy(() => import('pages/account/company/Subscription/PaymentMenu'))
const PaymentHistory = React.lazy(() => import('pages/account/company/Subscription/PaymentHistory'))
const SubscriptionDetails = React.lazy(() => import('pages/account/company/Subscription/SubscriptionDetails'))
const Notification = React.lazy(() => import('pages/notification/notification'))

import Index from 'pages/salesDashboard/Index'
import AnalyticsIndex from 'pages/analytics/AnalyticsIndex'
import SalesReport from 'pages/salesreport/SalesReport'
import socketIO from 'socket.io-client'
import Messages from 'components/chat/fullPage'
import Orders from 'pages/orders/Orders'
import OrderListDetails from 'pages/orders/OrderListdetails'
import Advertisement from 'pages/advertisement/advertisement'
import Trends from 'pages/trends-alert/trends'
import RefundCommericalGesture from 'pages/RefundCommericalGesture/create/index'
import RefundCommericalGestureList from 'pages/RefundCommericalGesture/list'
import AdvertisementRawData from 'pages/advertisement/advertisementRawData'
import HnmReturns from 'pages/HnmReturns'

const socket = socketIO.connect(process.env.REACT_APP_CHAT_API, {
  transports: ['websocket']
})

const routesList = [
  {
    path: '/',
    isIndex: true,
    name: 'Dashboard',
    component: <Dashboard />,
    exact: true,
    role: 5,
    pricing: 3,
    pomUserType: [1]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: <Dashboard />,
    exact: true,
    role: 5,
    pricing: 3,
    pomUserType: [1]
  },

  {
    path: '/inventory_data',
    name: 'StocksView',
    component: <StocksView />,
    pomUserType: [3],
    exact: true,
    role: 2,
    pricing: 2
  },
  {
    path: '/orders',
    name: 'Orders',
    component: <Orders />,
    exact: true,
    role: 2,
    pricing: 1,
    pomUserType: [1, 3, 4]
  },
  {
    path: '/salesdashboard',
    name: 'SalesDashboard',
    component: <Index />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: <AnalyticsIndex />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/salesreport',
    name: 'Sales Report',
    component: <SalesReport />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/advertisement',
    name: 'Advertisement',
    component: <Advertisement />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/advertisement/rawData',
    name: 'Raw Data',
    component: <AdvertisementRawData />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  // {
  //   path: '/analytics',
  //   name: 'Analytics',
  //   component: <AnalyticsIndex />,
  //   exact: true,
  //   role: 2,
  //   pricing: 1
  // },

  {
    path: '/refund/create',
    name: 'Refund & Commerical',
    component: <RefundCommericalGesture />,
    exact: true,
    role: 2,
    pricing: 1,
    pomUserType: [1, 3, 4]
  },
  {
    path: '/refund/list',
    name: 'Refund & Commerical',
    component: <RefundCommericalGestureList />,
    exact: true,
    role: 2,
    pricing: 1,
    pomUserType: [1, 3, 4]
  },
  {
    path: '/purchaseorders',
    name: 'purchaseorders',
    component: <CatalogueList />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/purchaseOrder/view',
    name: 'All',
    component: <CatalogueList />,
    exact: true,
    role: 2,
    pricing: 2,
    pomUserType: [1, 2]
  },

  {
    path: '/purchaseOrder/create',
    name: 'purchaseOrder',
    component: <AddPO />,
    exact: true,
    pomUserType: [1],
    role: 3,
    pricing: 2
  },
  {
    path: '/purchaseOrder/edit/:poNumber',
    name: 'purchaseOrder',
    component: <AddPO />,
    exact: true,
    role: 3,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/purchaseOrder/:poNumber',
    name: 'purchaseOrder',
    component: <PurchaseOrderDetails socket={socket} />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/order/:orderId',
    name: 'Orders',
    component: <OrderListDetails socket={socket} />,
    exact: true,
    role: 1,
    pomUserType: [1, 3, 4],
    pricing: 1
  },
  {
    path: '/order/create',
    name: 'Orders',
    component: <Create />,
    exact: true,
    role: 1,
    pomUserType: [3],
    pricing: 1
  },
  {
    path: '/account/company/vendors/:id',
    name: 'Vendor',
    component: <ViewVendorDetails />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 2
  },
  {
    path: '/purchaseOrder/delivery/:poNumber',
    name: 'purchaseOrder',
    component: <Delivery />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/projects/delivery/:projectId',
    name: 'projects',
    component: <ProjectDelivery />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/project/history/:projectId',
    name: 'projects',
    component: <ProjectHistory socket={socket} />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/purchaseOrder/delivery/sku/:poNumber',
    name: 'purchaseOrder',
    component: <SkuDelivery />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/projects/delivery/po/:projectId',
    name: 'projects',
    component: <PoDelivery />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/projects/list',
    name: 'projects',
    component: <AddP />,
    exact: true,
    pomUserType: [1],
    role: 2,
    pricing: 4
  },
  {
    path: '/project/edit/:projectId',
    name: 'projects',
    component: <AddP />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/purchaseOrder/:poNumber',
    name: 'purchaseOrder',
    component: <PurchaseOrderDetails />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/projectsdetails/:projectId',
    name: 'purchaseOrder',
    component: <ProjectsDetails socket={socket} />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },

  {
    path: '/projects',
    name: 'Projects',
    component: <ProjectMenu />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },

  {
    path: '/products',
    name: 'Products',
    component: <ProductsList />,
    exact: true,
    role: 2,
    pricing: 1,
    pomUserType: [1, 2]
  },
  { path: '/orders', name: 'Orders', component: <Orders />, exact: true, role: 2, pricing: 1, pomUserType: [1, 3, 4] },
  { path: '/inventory_data', name: 'StocksView', component: <StocksView />, pomUserType: [3], exact: true, role: 2, pricing: 2 },
  { path: '/salesdashboard', name: 'SalesDashboard', component: <Index />, exact: true, role: 2, pomUserType: [1], pricing: 4 },
  { path: '/analytics', name: 'Analytics', component: <AnalyticsIndex />, exact: true, role: 2, pomUserType: [1], pricing: 4 },
  { path: '/trends', name: 'Trends Alert', component: <Trends />, exact: true, role: 2, pomUserType: [1], pricing: 4 },
  { path: '/purchaseorders', name: 'purchaseorders', component: <CatalogueList />, exact: true, role: 2, pomUserType: [1, 2], pricing: 2 },
  { path: '/purchaseOrder/view', name: 'All', component: <CatalogueList />, exact: true, role: 2, pricing: 2, pomUserType: [1, 2] },
  { path: '/purchaseOrder/create', name: 'purchaseOrder', component: <AddPO />, exact: true, pomUserType: [1], role: 3, pricing: 2 },
  { path: '/purchaseOrder/edit/:poNumber', name: 'purchaseOrder', component: <AddPO />, exact: true, role: 3, pomUserType: [1], pricing: 2 },
  { path: '/purchaseOrder/:poNumber', name: 'purchaseOrder', component: <PurchaseOrderDetails socket={socket} />, exact: true, role: 2, pomUserType: [1, 2], pricing: 2 },
  { path: '/order/:orderId', name: 'Orders', component: <OrderListDetails socket={socket} />, exact: true, role: 1, pomUserType: [1, 3, 4], pricing: 1 },
  {
    path: '/inventory_data',
    name: 'StocksView',
    component: <StocksView />,
    pomUserType: [3],
    exact: true,
    role: 2,
    pricing: 2
  },
  {
    path: '/salesdashboard',
    name: 'SalesDashboard',
    component: <Index />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: <AnalyticsIndex />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/purchaseorders',
    name: 'purchaseorders',
    component: <CatalogueList />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/purchaseOrder/view',
    name: 'All',
    component: <CatalogueList />,
    exact: true,
    role: 2,
    pricing: 2,
    pomUserType: [1, 2]
  },
  {
    path: '/purchaseOrder/create',
    name: 'purchaseOrder',
    component: <AddPO />,
    exact: true,
    pomUserType: [1],
    role: 3,
    pricing: 2
  },
  {
    path: '/purchaseOrder/edit/:poNumber',
    name: 'purchaseOrder',
    component: <AddPO />,
    exact: true,
    role: 3,
    pomUserType: [1],
    pricing: 2
  },
  {
    path: '/purchaseOrder/:poNumber',
    name: 'purchaseOrder',
    component: <PurchaseOrderDetails socket={socket} />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/order/:orderId',
    name: 'Orders',
    component: <OrderListDetails socket={socket} />,
    exact: true,
    role: 1,
    pomUserType: [1, 3, 4],
    pricing: 1
  },
  { path: '/order/create', name: 'Orders', component: <Create />, exact: true, role: 1, pomUserType: [3], pricing: 1 },
  {
    path: '/account/company/vendors/:id',
    name: 'Vendor',
    component: <ViewVendorDetails />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 2
  },
  {
    path: '/purchaseOrder/delivery/:poNumber',
    name: 'purchaseOrder',
    component: <Delivery />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/projects/delivery/:projectId',
    name: 'projects',
    component: <ProjectDelivery />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/purchaseOrder/delivery/sku/:poNumber',
    name: 'purchaseOrder',
    component: <SkuDelivery />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/projects/delivery/po/:projectId',
    name: 'projects',
    component: <PoDelivery />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  { path: '/projects/list', name: 'projects', component: <AddP />, exact: true, pomUserType: [1], role: 2, pricing: 4 },
  {
    path: '/project/edit/:projectId',
    name: 'projects',
    component: <AddP />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/purchaseOrder/:poNumber',
    name: 'purchaseOrder',
    component: <PurchaseOrderDetails />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/projectsdetails/:projectId',
    name: 'purchaseOrder',
    component: <ProjectsDetails socket={socket} />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/projects',
    name: 'Projects',
    component: <ProjectMenu />,
    exact: true,
    role: 2,
    pomUserType: [1],
    pricing: 4
  },
  {
    path: '/products/view',
    name: 'All',
    component: <ProductsList />,
    exact: true,
    role: 2,
    pricing: 1,
    pomUserType: [1, 2]
  },
  { path: '/products/add', name: 'Add', component: <AddProduct />, exact: true, role: 3, pricing: 1, pomUserType: [1] },
  {
    path: '/products/:id/edit',
    name: 'Edit',
    component: <AddProduct />,
    exact: true,
    role: 3,
    pricing: 1,
    pomUserType: [1]
  },
  {
    path: '/account',
    name: 'Account',
    component: <AccountMenu />,
    exact: true,
    role: 1,
    pricing: 1,
    pomUserType: [1, 2, 3, 4]
  },
  {
    path: '/account/security',
    name: 'Security',
    component: <Security />,
    exact: true,
    role: 1,
    pomUserType: [1, 2, 3, 4],
    pricing: 1
  },
  {
    path: '/account/profile',
    name: 'Profile',
    component: <Profile />,
    exact: true,
    role: 1,
    pomUserType: [1, 2, 3, 4],
    pricing: 1
  },
  {
    path: '/account/company',
    name: 'Company',
    component: <CompanyMenu />,
    exact: true,
    role: 4,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/account/company/payment',
    name: 'Payment',
    component: <PaymentMenu />,
    exact: true,
    role: 4,
    pricing: 2,
    pomUserType: [1, 2]
  },
  {
    path: '/account/company/subscription',
    name: 'Subscription',
    component: <SubscriptionDetails />,
    exact: true,
    role: 4,
    pricing: 2,
    pomUserType: [1, 2]
  },
  {
    path: '/account/company/payment/history',
    name: 'Payment',
    component: <PaymentHistory />,
    exact: true,
    role: 4,
    pricing: 2,
    pomUserType: [1, 2]
  },
  {
    path: '/account/company/warehouse',
    name: 'Manage Warehouse',
    component: <ManageWarehouse />,
    exact: true,
    role: 4,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/account/company/warehouse/CreateWarehouse',
    name: 'Create Warehouse',
    component: <CreateWareHouse />,
    exact: true,
    pomUserType: [1, 2],
    role: 4,
    pricing: 2
  },
  {
    path: '/warehouse/edit/:id',
    name: 'Update Warehouse',
    component: <CreateWareHouse />,
    exact: true,
    pomUserType: [1, 2],
    role: 4,
    pricing: 2
  },
  {
    path: '/account/company/detail',
    name: 'Details',
    component: <Details />,
    exact: true,
    role: 4,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/account/company/users',
    name: 'Manage Users',
    component: <ManageUsers />,
    exact: true,
    role: 4,
    pomUserType: [1, 2],
    pricing: 2
  },
  {
    path: '/account/company/retailusers',
    name: 'Manage Users',
    component: <ManageRetailUsers />,
    exact: true,
    role: 5,
    pomUserType: [1],
    pricing: 2
  },
  {
    path: '/account/company/vendors',
    name: 'Manage Vendors',
    component: <ManageUsers />,
    exact: true,
    role: 4,
    pomUserType: [1],
    pricing: 2
  },
  {
    path: '/account/company/retailshop',
    name: 'Manage Retail Shop',
    component: <ManageRetailShop />,
    exact: true,
    role: 4,
    pomUserType: [1],
    pricing: 2
  },
  {
    path: '/messenger',
    name: 'Messanger',
    component: <Messages socket={socket} />,
    exact: true,
    role: 2,
    pomUserType: [1, 2, 4],
    pricing: 2
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: <Notification />,
    exact: true,
    role: 2,
    pomUserType: [1, 2, 4],
    pricing: 2
  },
  {
    path: '/messenger/:catalogueId',
    name: 'Messanger',
    component: <Messages socket={socket} />,
    exact: true,
    role: 3,
    pomUserType: [1, 2, 4],
    pricing: 2
  },
  {
    path: '/products/:id/product-view',
    name: 'Product View',
    pomUserType: [1, 2],
    component: <ProductView />,
    exact: true,
    role: 3,
    pricing: 2
  },
  {
    path: '/returns',
    name: 'hnmreturns',
    component: <HnmReturns />,
    exact: true,
    role: 2,
    pomUserType: [1, 2],
    pricing: 2
  },
]

export default routesList
