import React, { useState } from 'react'
import { CButton, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'

import './Button.css'

const Button = (props) => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(hover)
  }

  return (
    <CButton
      data-testid={props.datatestid}
      color={props.color || 'primary'}
      className={`px-4 ${props.buttonClass}`}
      type={props.type}
      disabled={props.disabled || props.isLoading}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      onClick={props.onClick}
    >
      {props.isIconBefore && (
        <>
          {props.isLoading
            ? (
              <CSpinner color='primary' className='loading-icon' />
              )
            : hover
              ? (
                <>{props.hoverBefore}</>
                )
              : (
                <>{props.hoverAfter}</>
                )}
        </>
      )}
      {props.label}
      {props.isLoading
        ? (
          <CSpinner color='primary' className='loading-icon' />
          )
        : (
            props.isIconAfter && <>{hover ? <>{props.hoverBefore}</> : <>{props.hoverAfter}</>}</>
          )}
    </CButton>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  isIconAfter: PropTypes.bool,
  isIconBefore: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  buttonClass: PropTypes.string,
  onClick: PropTypes.func,
  hoverBefore: PropTypes.object,
  hoverAfter: PropTypes.object,
  isLoading: PropTypes.bool,
  datatestid: PropTypes.string
}

export default Button
