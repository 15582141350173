import React, { useState } from 'react'
import {
  Grid,
  Paper,
  IconButton,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material'
import { CCol, CRow } from '@coreui/react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { styled } from '@mui/material/styles'
import { AppBreadcrumb } from 'sections/layout/dashboard'
import { useProjectsDetailsHooks } from './useProjectsDetailsHook'
import { getDate, formatDateWithAMPM } from 'shared/util/helper'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#3c4b64',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({}) => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const ProjectHistory = (props) => {
  const { allPoData, projectHistory, projectDetail, isPoLoading, isHistoryLoad, breadCrumbPath } =
    useProjectsDetailsHooks(props)

  const [open, setOpen] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)

  const columns_header = [
    { id: 'projectId', label: 'Project ID', minWidth: 170 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    { id: 'updated_at', label: 'Updated At', minWidth: 170 },
    { id: 'status', label: 'Status', minWidth: 170 },
    { id: 'action', label: 'Action', minWidth: 170 }
  ]

  const [rowsPerPageInner, setrowsPerPageInner] = useState(10)
  const [pageInner, setPage] = useState(0)
  const rowsInner = projectHistory || []

  const handleChangePageInner = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangerowsPerPageinner = (event) => {
    setrowsPerPageInner(+event.target.value)
    setPage(0)
  }

  const handleOpen = (row) => {
    const updatedRow = { ...row }

    const purchaseOrderChanges = updatedRow.projectData.find((change) => change.fieldName === 'purchaseOrder')

    if (purchaseOrderChanges) {
      const updatePoValues = (poArray) => {
        return poArray.map((po) => {
          const matchedPo = allPoData?.find((res) => res._id === po.poId)
          return {
            poId: po.poId,
            poNumber: matchedPo ? matchedPo.poNumber : `PO Number not found`
          }
        })
      }
      purchaseOrderChanges.oldValue = updatePoValues(purchaseOrderChanges.oldValue)
      purchaseOrderChanges.newValue = updatePoValues(purchaseOrderChanges.newValue)
    }

    setSelectedRowData(updatedRow)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedRowData(null)
  }

  const renderValue = (value, fieldName) => {
    if (fieldName === 'purchaseOrder' && Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, idx) => (
            <li key={idx}>
              <strong>PO Id:&nbsp;</strong> {item.poId}
              <br />
              <strong>PO Number:&nbsp;</strong> {item.poNumber}
            </li>
          ))}
        </ul>
      )
    } else if (!isNaN(Date.parse(value))) {
      return formatDateWithAMPM(value)
    } else {
      return value
    }
  }

  console.log(selectedRowData, 'selectedRowData')

  return (
    <>
      <div className='p-5'>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <AppBreadcrumb pathNameDeclare={breadCrumbPath} />
            <h3>Projects History</h3>
            <p>Detail of your Projects History</p>
          </Grid>
        </Grid>

        <>
          {isPoLoading ? (
            <Skeleton animation='wave' variant='rectangle' width='100%' height='350px' />
          ) : (
            <>
              <CRow className='ms-2 mt-5'>
                <CCol>
                  <CRow>
                    <CCol sm={6} lg={6}>
                      <CRow>
                        <CCol>
                          <h5>Project ID</h5>
                        </CCol>
                        <CCol>
                          <p>{projectDetail.projectId}</p>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <h5>Load Date</h5>
                        </CCol>
                        <CCol>
                          <p>{getDate(projectDetail.loadDate)}</p>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <h5>Freight Line</h5>
                        </CCol>
                        <CCol>
                          <p>{projectDetail.freightLine}</p>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <h5> Container</h5>
                        </CCol>
                        <CCol>
                          <p>{projectDetail.container}</p>
                        </CCol>
                      </CRow>
                    </CCol>

                    <CCol sm={6} lg={6}>
                      <CRow>
                        <CCol>
                          <h5>Arrival Port</h5>
                        </CCol>
                        <CCol>
                          <p>{projectDetail.arrivalPort}</p>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <h5>Arrival Warehouse</h5>
                        </CCol>
                        <CCol>
                          <p>{projectDetail.arrivalWarehouse}</p>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <h5>Delivery Date</h5>
                        </CCol>
                        <CCol>
                          <p>{getDate(projectDetail.deliveryDate)}</p>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <h5>Project Stage</h5>
                        </CCol>
                        <CCol>
                          <p>{projectDetail.projectStage}</p>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </>
          )}
        </>

        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <h3 style={{ paddingTop: '30px' }}>History Table</h3>
          </Grid>
        </Grid>

        <Grid direction='column' pt={4}>
          <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow style={{ backgroundColor: 'white' }}>
                  {columns_header.map((column) => (
                    <StyledTableCell key={column.id} align='center' style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isHistoryLoad
                  ? // Display a skeleton for each row while loading
                    [...Array(rowsPerPageInner)].map((_, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell colSpan={columns_header.length} align='center'>
                          <Skeleton animation='wave' variant='rectangular' width='100%' height={40} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : rowsInner
                      .slice(pageInner * rowsPerPageInner, pageInner * rowsPerPageInner + rowsPerPageInner)
                      .map((row) => (
                        <StyledTableRow key={row.projectId}>
                          {columns_header.map((column) => {
                            let value = row[column.id]

                            if (column.id === 'updated_at') {
                              value = formatDateWithAMPM(row.timestamp)
                            } else if (column.id === 'status') {
                              value = row.action.toUpperCase()
                            } else if (column.id === 'name') {
                              value = `${row.user?.fName || ''} ${row.user?.lName || ''}`.trim()
                            } else if (column.id === 'email') {
                              value = row.user?.email || ''
                            }

                            return column.id === 'action' ? (
                              <StyledTableCell key={column.id} align='center'>
                                <IconButton
                                  aria-label='view'
                                  onClick={() => handleOpen(row)} // Open modal with the selected row data
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell key={column.id} align='center'>
                                {value}
                              </StyledTableCell>
                            )
                          })}
                        </StyledTableRow>
                      ))}
              </TableBody>
            </Table>
          </TableContainer>
          {rowsInner.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              style={{ marginTop: '10px' }}
              count={rowsInner.length}
              rowsPerPage={rowsPerPageInner}
              page={pageInner}
              onPageChange={handleChangePageInner}
              onrowsPerPageChange={handleChangerowsPerPageinner}
              data-testid='table-pagination'
            />
          )}
        </Grid>
      </div>

      {/* Modal for Project Details */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
        <DialogTitle>Project Details</DialogTitle>
        <DialogContent dividers style={{ paddingTop: '0px' }}>
          <div style={{ padding: '20px' }}>
            <Typography variant='h6' gutterBottom>
              Project Overview
            </Typography>
            {projectDetail && selectedRowData && selectedRowData.action === 'create' && (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant='body1' style={{ marginBottom: '10px', marginTop: '20px' }}>
                    <strong>Project ID:</strong> {projectDetail.projectId}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Vessel Name:</strong> {projectDetail.vesselName}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Freight Line:</strong> {projectDetail.freightLine}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Booking Number:</strong> {projectDetail.bookingNumber}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Container:</strong> {projectDetail.container}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Departure Port:</strong> {projectDetail.departurePort}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Project Stage:</strong> {projectDetail.projectStage}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Created At:</strong> {formatDateWithAMPM(projectDetail.createdAt)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1' style={{ marginBottom: '10px', marginTop: '20px' }}>
                    <strong>Arrival Port:</strong> {projectDetail.arrivalPort}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Arrival Warehouse:</strong> {projectDetail.arrivalWarehouse}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Start Date:</strong> {formatDateWithAMPM(projectDetail.startDate)}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Load Date:</strong> {formatDateWithAMPM(projectDetail.loadDate)}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Cut Off Date:</strong> {formatDateWithAMPM(projectDetail.cutOffDate)}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Departure Date:</strong> {formatDateWithAMPM(projectDetail.departureDate)}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Arrival Date:</strong> {formatDateWithAMPM(projectDetail.arrivalDate)}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Delivery Date:</strong> {formatDateWithAMPM(projectDetail.deliveryDate)}
                  </Typography>
                  <Typography variant='body1' style={{ marginBottom: '10px' }}>
                    <strong>Updated At:</strong> {formatDateWithAMPM(projectDetail.updatedAt)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {selectedRowData && selectedRowData.action === 'edit' && (
              <Grid container spacing={3}>
                {selectedRowData?.projectData && selectedRowData.projectData.length > 0 ? (
                  selectedRowData.projectData.map((item, index) => {
                    return (
                      <Grid container item xs={12} key={index} spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='div'>
                            <strong>{item.fieldName}</strong>
                          </Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant='body2'>
                              <strong>Old Value:</strong> {renderValue(item.oldValue, item.fieldName)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant='body2'>
                              <strong>New Value:</strong> {renderValue(item.newValue, item.fieldName)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                ) : (
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='body1'>No Data Found!</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ProjectHistory
