import { useQuery } from 'react-query'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useRefundListHooks = () => {
  const navigate = useNavigate()
  const newParam = new URLSearchParams(location.search)
  const [skupage, setSkuPage] = useState(0)
  const [skurowsPerPage, setSkuRowsPerPage] = useState(50)
  const [marketplace, setMarketplace] = useState([])
  // const [refundList, setRefundList] = useState([])

  const handleSkuChangePage = (event, newPage) => {
    setSkuPage(newPage)
  }
  const handleSkuChangeRowsPerPage = (event) => {
    setSkuRowsPerPage(parseInt(event.target.value, 10))
    setSkuPage(0)
  }

  const onChangeFilter = (name, value) => {
    if (value) {
      newParam.set(name, value)
    } else {
      newParam.delete(name)
      newParam.delete('end_date')
      newParam.delete('sku_end_date')
    }
    refetch()
    navigate({ search: newParam.toString() })
  }

  const fetchRefundList = async () => {
    try {
      const transaction_type = newParam.get('transaction_type')
      const marketplace_name = newParam.get('marketplace_name')
      const refund_status = newParam.get('refund_status')
      const startDate = newParam.get('start_date')
      const endDate = newParam.get('end_date')
      let query = {}
      if (transaction_type) {
        query.transaction_type = transaction_type
      }
      if (marketplace_name) {
        query.marketplace_name = marketplace_name
      }
      if (refund_status) {
        query.refund_status = refund_status
      }
      if (startDate && endDate) {
        query.start_date = startDate
        query.end_date = endDate
      }
      const queryParams = new URLSearchParams(query)
      const response = await purchaseOrderService.getRefundList(queryParams)

      return response?.data
    } catch (error) {
      throw error
    }
  }
  const fetchMarketplaceList = async () => {
    try {
      const response = await purchaseOrderService.getMarketplaceList()

      return response?.data
    } catch (error) {
      throw error
    }
  }
  const { refetch, data: refundList, isLoading: isLoading } = useQuery('refundlist', () => fetchRefundList(), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
    },
    onError: (error) => {
      console.log(error)
    }
    // enabled: param.projectId ? true : false
  })

  const { isLoading: isMarketPlaceLoading } = useQuery('marketplacelist', () => fetchMarketplaceList(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMarketplace(data?.marketplace_name)
    },
    onError: (error) => {
      console.log(error)
    }
    // enabled: param.projectId ? true : false
  })
  const resetFilter = () => {
    newParam.delete('transaction_type')
    newParam.delete('refund_status')
    newParam.delete('marketplace_name')
    newParam.delete('start_date')
    newParam.delete('end_date')
    navigate({ pathname: `/refund/list` })
    refetch()
  }

  return {
    isLoading,
    newParam,
    skurowsPerPage,
    skupage,
    handleSkuChangePage,
    handleSkuChangeRowsPerPage,
    onChangeFilter,
    marketplace,
    resetFilter,
    refundList,
    isMarketPlaceLoading
  }
}
