import axios from 'axios'
import authHeader from './auth-header'

// Catalogue Section
const getCatalogue = async (code) => {
  return axios.get(`${process.env.REACT_APP_API}/ecatalogue/${code}`, {
    timeout: process.env.REACT_APP_API_TIMEOUT
  })
}

const updateCatalogueInfo = (id, catalog) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API}/catalogue/${id}`,
      catalog,
      { headers: authHeader('application/json; charset=UTF-8') },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getCatalogueDetails = (id) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/catalogue/${id}`,
      { headers: authHeader('application/json; charset=UTF-8') },
      {
        timeout: process.env.REACT_APP_API_TIMEOUT
      }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getAllCatalogues = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/getallcompanycat`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}
const getQuery = () => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getcatquery`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const createCatalogue = (input) => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/createcat`,
      input,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const updateCatalogue = (code, input) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API}/ecatalogue/${code}`,
      input,
      { headers: authHeader('application/json; charset=UTF-8') },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}



const getUserQueries = (userId, companyId) => {
  return axios.get(
    `${process.env.REACT_APP_API}/getuserqueries/${userId}/${companyId}`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

// meta section
const getUnreadUserQueresCount = (userId) => {
  return axios.get(
    `${process.env.REACT_APP_API}/cmetauser/${userId}`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const getUnreadCompanyQueresCount = (companyId) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/cmetacompany/${companyId}`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}



const updateInterest = async (id, input) => {
  return axios
    .patch(`${process.env.REACT_APP_API}/updateinterest/${id}`, input, {
      timeout: process.env.REACT_APP_API_TIMEOUT
    })
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getInterest = (code) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getinterest/${code}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

// Dashboard section
const getCatalogues = (companyId) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getcatcount?companyId=${companyId}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getDownloadCount = (companyId, count) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getdownloadedcount/${companyId}/${count}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getQueriesCount = (companyId) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getquerycount?companyId=${companyId}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}



const deleteCatalogue = (catalogueId) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API}/catalogue/${catalogueId}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const catalogueServices = {
  getCatalogue,
  updateCatalogueInfo,
  getCatalogueDetails,
  getAllCatalogues,
  createCatalogue,
  updateInterest,
  getUnreadUserQueresCount,
  getUnreadCompanyQueresCount,
  getCatalogues,
  getQueriesCount,
  getDownloadCount,
  getUserQueries,
  updateCatalogue,
  getInterest,
  deleteCatalogue,
  getQuery
}

export default catalogueServices
