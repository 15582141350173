import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardMedia } from '@mui/material'
import { getExtension } from 'shared/util/helper'

const ImageMetadata = ({ data }) => {
  return (
    <Card data-testid='imgMetaData' sx={{ height: '15rem' }}>
      <CardMedia
        image={URL.createObjectURL(data)}
        height='75%'
        component='img'
        // style={{
        //   height: '8rem',
        // }}
      />
      <CardContent>
        <strong>{data.name.length > 10 ? getExtension(data.name) : data.name}</strong>(
        {(data.size / 1000000).toPrecision(2)} MB)
      </CardContent>
    </Card>
  )
}

ImageMetadata.propTypes = {
  data: PropTypes.array
}

export default ImageMetadata
