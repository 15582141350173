import React from 'react'
import PropTypes from 'prop-types' // Import PropTypes

import { Modal, Button, CircularProgress } from '@mui/material'

const CustomModal = ({ open, onClose, title, onSubmit, isLoading, children }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className='modal-css'>
        <h3>{title}</h3>
        {children}
        <div style={{ marginTop: '20px' }}>
          <Button variant='contained' color='primary' onClick={onSubmit} disabled={isLoading}>Submit</Button>
          {isLoading ? <CircularProgress size={20} style={{ marginLeft: '10px' }} /> : null}
        </div>
      </div>
    </Modal>
  )
}

// PropTypes validation
CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default CustomModal
