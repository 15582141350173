import React from 'react'
import { Grid, TableRow, TableCell, Table, TableHead, TableBody, TableContainer, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import { calculatePercentageDifference } from 'shared/util/helper'

const SalesReportShopWiseTable = (props) => {
  const { stockAlertData = [], loading } = props

  const totalQty2024 = stockAlertData?.shopSales?.reduce((acc, curr) => acc + (curr?.qty_2024 || 0), 0)
  const totalUntaxed2024 = stockAlertData?.shopSales?.reduce((acc, curr) => acc + (curr?.untaxed_2024 || 0), 0)
  const totalQty2023 = stockAlertData?.shopSales?.reduce((acc, curr) => acc + (curr?.qty_2023 || 0), 0)
  const totalUntaxed2023 = stockAlertData?.shopSales?.reduce((acc, curr) => acc + (curr?.untaxed_2023 || 0), 0)

  return (
    <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Shops</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>QTY Ordered 2023</strong>
              </TableCell>

              <TableCell sx={{ textAlign: 'center' }}>
                <strong>QTY Ordered 2024</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Variance(QTY)</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Untaxed Total 2023</strong>
              </TableCell>

              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Untaxed Total 2024</strong>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <strong>Variance(Untaxed)</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(stockAlertData?.shopSales) && stockAlertData?.shopSales.length ? (
              stockAlertData?.shopSales.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: 'center' }}>{item?.shop || '--'}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>{item?.qty_2023 || 0}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>{item?.qty_2024 || 0}</TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: calculatePercentageDifference(item?.qty_2024, item?.qty_2023) < 0 ? 'red' : 'green'
                    }}
                  >
                    {Math.abs(calculatePercentageDifference(item?.qty_2024, item?.qty_2023).toFixed(2))} %
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {item?.untaxed_2023 ? item.untaxed_2023.toFixed(2) : 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {item?.untaxed_2024 ? item.untaxed_2024.toFixed(2) : 0}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: calculatePercentageDifference(item?.qty_2024, item?.qty_2023) < 0 ? 'red' : 'green'
                    }}
                  >
                    {Math.abs(
                      calculatePercentageDifference(item?.untaxed_2024, item?.untaxed_2023)
                        .toFixed(2)
                        .replace(/[.,]00$/, '')
                    )}{' '}
                    %
                  </TableCell>
                </TableRow>
              ))
            ) : !loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 30,
                      marginBottom: 30
                    }}
                  >
                    <p className='text-center'>No Records Found...</p>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}

            {stockAlertData?.shopSales?.length > 0 && (
              <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: '#1976d2' }}>
                  <strong>Total</strong>
                </TableCell>

                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{totalQty2023}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{totalQty2024}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {calculatePercentageDifference(totalQty2024, totalQty2023)
                    .toFixed(2)
                    .replace(/[.,]00$/, '')}
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{totalUntaxed2023.toFixed(2)}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{totalUntaxed2024.toFixed(2)}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {calculatePercentageDifference(totalUntaxed2024, totalUntaxed2023)
                    .toFixed(2)
                    .replace(/[.,]00$/, '')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <div className='d-flex mt-4 justify-content-center align-items-center'>
          <CircularProgress />
        </div>
      )}
    </Grid>
  )
}

SalesReportShopWiseTable.propTypes = {
  stockAlertData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

export default SalesReportShopWiseTable
