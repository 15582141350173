import { Grid, Hidden } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import './analytics.css'
import moment from 'moment'
import { camelCaseToTitleCase } from 'shared/util/helper'

const ShopFilter = (props) => {
    const { selectedYear, selectedShipmentCountry, yearSelectStartDate, yearSelectEndDate, selectedOption, searchShop } = props
    return (
        <Grid container direction='row' justifyContent='space-between' spacing={2}>
            {selectedYear && (
                <Grid item md={4} className='gridItem'>
                    <Grid item md={12} pt={1}>
                        <span className='heading'>Year</span>
                    </Grid>
                    <Grid item md={12} className='paddingB'>
                        <span className='text'>{camelCaseToTitleCase(selectedYear)}</span>
                    </Grid>
                </Grid>
            )}
            {selectedShipmentCountry && (
                <Grid item md={4} className='gridItem'>
                    <Grid item md={12} pt={1}>
                        <span className='heading'>Shipment Country</span>
                    </Grid>
                    <Grid item md={12}>
                        <span className='text'>{selectedShipmentCountry}</span>
                    </Grid>
                </Grid>
            )}
            <Hidden mdUp={!yearSelectStartDate && !yearSelectEndDate && !selectedOption && !searchShop}>
                {yearSelectStartDate && (
                    <Grid item md={4} className='gridItem'>
                        <Grid item md={12} pt={1}>
                            <span className='heading'>Start Date</span>
                        </Grid>
                        <Grid item md={12}>
                            <span className='text'>{moment(yearSelectStartDate).format("MMMM Do YYYY")}</span>
                        </Grid>
                    </Grid>
                )}
                {yearSelectEndDate && (
                    <Grid item md={4} className='gridItem'>
                        <Grid item md={12} pt={1}>
                            <span className='heading'>End Date</span>
                        </Grid>
                        <Grid item md={12}>
                            <span className='text'>{moment(yearSelectEndDate).format("MMMM Do YYYY")}</span>
                        </Grid>
                    </Grid>
                )}
                {selectedOption && (
                    <Grid item md={4} className='gridItem'>
                        <Grid item md={12} pt={1}>
                            <span className='heading'>Option</span>
                        </Grid>
                        <Grid item md={12}>
                            <span className='text'>{camelCaseToTitleCase(selectedOption)}</span>
                        </Grid>
                    </Grid>
                )}
                {searchShop && (
                    <Grid item md={4} className='gridItem'>
                        <Grid item md={12} pt={1}>
                            <span className='heading'>Shop</span>
                        </Grid>
                        <Grid item md={12}>
                            <span className='text'>{searchShop}</span>
                        </Grid>
                    </Grid>
                )}
            </Hidden>
        </Grid>
    )
}

ShopFilter.propTypes = {
    selectedYear: PropTypes.string,
    selectedShipmentCountry: PropTypes.string,
    yearSelectStartDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    yearSelectEndDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    selectedOption: PropTypes.string,
    searchShop: PropTypes.string,
}

export default ShopFilter
