import React from 'react'
import PropTypes from 'prop-types'
import { isNotNullUndefinedOrEmpty, isNotUndefinedAndNull } from 'shared/util/helper'

const Supplier = (props) => {
  return (
    <>
      {isNotUndefinedAndNull(props.supplier)
        ? (
          <>
            {isNotNullUndefinedOrEmpty(props.supplier) && (
              <>
                <span>{props.supplier.split(" ")[0]}</span>
                <br />
              </>
            )}
          </>
          )
        : (
          <div className='cat-sub-title text-center'>---</div>
          )}
    </>
  )
}

Supplier.propTypes = {
  supplier: PropTypes.string
}

export default Supplier
