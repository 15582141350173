import { countries, employees, subscription, sortProductDropdown, sortDropdown ,CompanyType } from './Dropdowns'

const InnoSalesUrl = 'https://innoage.in/InnoSales'
const InnoAge = 'InnoAge Technologies Pvt. Ltd.'
const GenericError = 'Some Error Occured. Please Try again later!!!'
const VerificationFailedForCaptch = "Verification Failed with Google's Re-Captcha. Please verify you are human"
const ExcelDownloadPath = 'https://innoageimages.blob.core.windows.net/excel/Bulk Upload Sheet Format.xlsx'
const InnoSalesLogo = 'https://innoageimages.blob.core.windows.net/images/assets/pom.svg'

const Activity = {
  notReviewed: 'Not Reviewed',
  queried: 'Queried',
  shortlisted: 'ShortListed',
  reviewed: 'Reviewed'
}

const availablePricing = [
  {
    id: 1,
    name: 'Freemium',
    price: '₹ 0',
    priceUnit: '/per year',
    description: 'Most Sutable for Startups that want digital Product Inventory',
    features: [
      'Free Forever for Startups',
      '1 User',
      '100 SKU',
      '1000 Pictures',
      '1 GB Space',
      'Company Shop',
      'Access to Help Portal'
    ]
  },
  {
    id: 2,
    name: 'Standard',
    price: '₹ 24999',
    priceUnit: '/per year',
    description: 'For Companies that want to Start Export Business with Digital Presence',
    features: [
      '30 Days Free Trial',
      '3 Users',
      '500 SKUs',
      '5000 Pictures',
      '20 GB Space',
      'Company Shop',
      'Catalogue Generator',
      'PIS/PPT Downloads',
      '50 Catalogues',
      'Chat Box',
      'Access to Help Portal',
      '3 Day Training',
      'Onboarding Support & Demo'
    ]
  },
  {
    id: 3,
    name: 'Premium',
    price: '₹ 39999',
    priceUnit: '/per year',
    description: 'For Companies already in Export Business & want to grow further',
    features: [
      '30 Days Free Trial',
      '7 Users',
      '2000 SKUs',
      '100000 Pictures',
      '100 GB Space',
      'Company Shop',
      'Dashboard',
      '1000 Catalogues',
      'Catalogue Generator',
      'Activity Tracker',
      'PIS/PPT Downloads',
      'Advance Image Editor',
      'Chat Box',
      'Access to Help Portal',
      '1 Week Training',
      'Live Onboarding & Demo',
      'Priority Support',
      'Branding Support'
    ]
  },
  {
    id: 4,
    name: 'Elite',
    price: '₹ 49999',
    priceUnit: '/per year',
    description: 'For Large Enterprises that want to have everything on single Platform',
    isRecommended: true,
    features: [
      '30 Days Free Trial',
      'Unlimited Users',
      'Unlimited SKUs',
      'Unlimited Pictures',
      '150 GB Space',
      'Company Shop',
      'Dashboard',
      'Chat Box',
      'Quotation',
      'Unlimited Catalogues',
      'Catalogue Generator',
      'Activity Tracker',
      'PIS/PPT Downloads',
      'Advance Image Editor',
      'Bulk Import Products',
      'Bulk Edit Products',
      'Access to Help Portal',
      '1 Month Training',
      'Live Onboarding & Demo',
      'Priority Support',
      'Branding Support',
      'Access to Upcoming Features'
    ]
  }
]

const pptTemplateImages = [
  'https://res.cloudinary.com/innoage/image/upload/v1670397264/1_fcksd8.svg',
  'https://res.cloudinary.com/innoage/image/upload/v1670397263/2_xwnt8r.svg',
  'https://res.cloudinary.com/innoage/image/upload/v1670397263/3_aa01xh.svg',
  'https://res.cloudinary.com/innoage/image/upload/v1670397263/4_caj2go.svg',
  'https://res.cloudinary.com/innoage/image/upload/v1670397263/5_lku7vi.svg',
  'https://res.cloudinary.com/innoage/image/upload/v1670397263/6_u5lee7.svg'
]

const pptTemplate = [
  { image: 'https://res.cloudinary.com/innoage/image/upload/v1670397264/1_fcksd8.svg', productSize: 1, imageSize: 0 },
  { image: 'https://res.cloudinary.com/innoage/image/upload/v1670397263/2_xwnt8r.svg', productSize: 1, imageSize: 1 },
  { image: 'https://res.cloudinary.com/innoage/image/upload/v1670397263/3_aa01xh.svg', productSize: 1, imageSize: 2 },
  { image: 'https://res.cloudinary.com/innoage/image/upload/v1670397263/3_aa01xh.svg', productSize: 1, imageSize: 3 },
  { image: 'https://res.cloudinary.com/innoage/image/upload/v1670397263/3_aa01xh.svg', productSize: 1, imageSize: 4 },
  { image: 'https://res.cloudinary.com/innoage/image/upload/v1670397263/4_caj2go.svg', productSize: 2, imageSize: 1 },
  { image: 'https://res.cloudinary.com/innoage/image/upload/v1670397263/5_lku7vi.svg', productSize: 3, imageSize: 1 },
  { image: 'https://res.cloudinary.com/innoage/image/upload/v1670397263/6_u5lee7.svg', productSize: 4, imageSize: 1 }
]
const country = [
  { value: 'in', label: 'India' },
  { value: 'usa', label: 'USA' },
  { value: 'can', label: 'Canada' },
  { value: 'is', label: 'Israel' },
  { value: 'ch', label: 'China' },
  { value: 'fr', label: 'France' },
  { value: 'it', label: 'Italy' },
  { value: 'nz', label: 'New Zealand' },
  { value: 'sp', label: 'Spain' },
  { value: 'uk', label: 'United Kingdom' },
  { value: 'rf', label: 'Russian Federation' },
  { value: 'jp', label: 'Japan' }
]

const paginationPages = [
  {
    name: 30,
    label: 30
  },
  {
    name: 60,
    label: 60
  },
  {
    name: 120,
    label: 120
  },
  {
    name: 600,
    label: 600
  },
  {
    name: 1200,
    label: 1200
  }
]
const colorFilter = [
  { color: 'Blue' },
  { color: 'Gray' },
  { color: 'Brown' },
  { color: 'Mustard' },
  { color: 'Cream' },
  { color: 'Charcoal' },
  { color: 'Rust' }
]

const shopData = ["Maison", "Manomano", "BUT", "Bol", "OTTO", "Wayfair", "CDiscount", "Worten", "Amazon"]
const transactionType = ['refund','return','commercial_gesture']
const status = ["Partially Refunded" , "Refunded"]
const accountType = ['marketplace_refund','wire_refund','paypal_refund']
const dispatched = ['France','Germany','Spain','United Kingdom']
const shipped = ['Austria','Belgium','Finland','France','Germany','Italy','Netherlands','Poland','Portugal','Spain','Switzerland','United Kingdom']
const currencySign =['Euros','British pound','Swiss Franks','Polish Zloty']

const Constants = {
  InnoSalesUrl,
  InnoAge,
  GenericError,
  countries,
  employees,
  VerificationFailedForCaptch,
  ExcelDownloadPath,
  InnoSalesLogo,
  Activity,
  subscription,
  sortProductDropdown,
  sortDropdown,
  availablePricing,
  pptTemplateImages,
  pptTemplate,
  paginationPages,
  colorFilter,
  country,
  shopData,
  transactionType,
  status,
  accountType,
  dispatched,
  shipped,
  currencySign,
  CompanyType
}

export default Constants
