import React from 'react'
import {
    Grid,
    TableRow,
    TableCell,
    Table,
    TableHead,
    TableBody,
    TableContainer,
    Tooltip,
    CircularProgress
} from '@mui/material'
import PropTypes from 'prop-types'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'

const SalesDashboardTable = (props) => {
    const {
        handleSort,
        getColumnHeader,
        getIconStyle,
        loadedData,
        isLoading,
        tableContainerRef,
        viewType,
    } = props

    return (
        <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
            <TableContainer className="scroll-box" ref={tableContainerRef}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2}>
                                <strong>{viewType === 'product' ? 'SKU' : 'Design'}</strong>
                            </TableCell>
                            <TableCell rowSpan={2}><strong>Image</strong></TableCell>
                            <TableCell colSpan={2} align="center"><strong>Units Sold</strong></TableCell>
                            <TableCell colSpan={2} align="center"><strong>Untaxed Amount</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" onClick={() => handleSort('totalQty')}>
                                <strong>{getColumnHeader('2024', 'Current Month')}</strong>
                                <FaArrowUpLong style={getIconStyle('totalQty', 'asc')} />
                                <FaArrowDownLong style={getIconStyle('totalQty', 'desc')} />
                            </TableCell>
                            <TableCell align="center" onClick={() => handleSort('qty_2023')}>
                                <strong>{getColumnHeader('2023', 'Previous Month')}</strong>
                                <FaArrowUpLong style={getIconStyle('qty_2023', 'asc')} />
                                <FaArrowDownLong style={getIconStyle('qty_2023', 'desc')} />
                            </TableCell>
                            <TableCell align="center" onClick={() => handleSort('totalUntaxed')}>
                                <strong>{getColumnHeader('2024', 'Current Month')}</strong>
                                <FaArrowUpLong style={getIconStyle('totalUntaxed', 'asc')} />
                                <FaArrowDownLong style={getIconStyle('totalUntaxed', 'desc')} />
                            </TableCell>
                            <TableCell align="center" onClick={() => handleSort('untaxed_2023')}>
                                <strong>{getColumnHeader('2023', 'Previous Month')}</strong>
                                <FaArrowUpLong style={getIconStyle('untaxed_2023', 'asc')} />
                                <FaArrowDownLong style={getIconStyle('untaxed_2023', 'desc')} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...loadedData].map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.sku}</TableCell>
                                <TableCell>
                                    <Tooltip
                                        title={<img src={row?.image ? row?.image : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`} alt="Product" width="200px" />}
                                        arrow
                                        placement="right"
                                        PopperProps={{
                                            style: { marginLeft: '10px' } // Adjust the left margin as needed
                                        }}
                                    >
                                        <img src={
                                            row?.image ? row?.image : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                                        } alt="Product" width="60px" height="60px" />
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center">{row.totalQty ?? '-'}</TableCell>
                                <TableCell align="center">{row.qty_2023 ?? '-'}</TableCell>
                                <TableCell align="center">{row.totalUntaxed ? `€${row.totalUntaxed?.toFixed(2)}` : '-'}</TableCell>
                                <TableCell align="center">{row.untaxed_2023 ? `€${row.untaxed_2023?.toFixed(2)}` : '-'}</TableCell>
                            </TableRow>
                        ))}
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

SalesDashboardTable.propTypes = {
    handleSort: PropTypes.func.isRequired,
    getColumnHeader: PropTypes.func.isRequired,
    getIconStyle: PropTypes.func.isRequired,
    loadedData: PropTypes.arrayOf(PropTypes.shape({
        sku: PropTypes.string.isRequired,
        image: PropTypes.string,
        totalQty: PropTypes.number,
        qty_2023: PropTypes.number,
        totalUntaxed: PropTypes.number,
        untaxed_2023: PropTypes.number,
    })).isRequired,
    isLoading: PropTypes.bool.isRequired,
    tableContainerRef: PropTypes.object.isRequired,
    viewType: PropTypes.string.isRequired,
}

export default SalesDashboardTable
