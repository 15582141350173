import React from 'react'
import PropTypes from 'prop-types'

import './index.css'
import { getChatMessageDateTime } from 'shared/util/helper'

const Company = (props) => {
  return (
    <div className='d-flex flex-row justify-content-end' data-testid='company' key={props.index}>
      <div className='message-text'>
        <p className='small p-2 me-3 mb-1 text-white rounded-3' style={{ backgroundColor: '#6495ED' }}>
          <>{props.message}</>
        </p>
        <p className='small me-3 mb-3 rounded-5 text-muted'>{getChatMessageDateTime(props.createdOn)}</p>
      </div>
    </div>
  )
}

Company.propTypes = {
  message: PropTypes.string,
  createdOn: PropTypes.instanceOf(Date),
  index: PropTypes.number,
  logo: PropTypes.string,
  products: PropTypes.array
}

export default Company
