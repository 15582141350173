import { React } from 'react'
import { IconButton, Badge, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { BiMessageAltDetail } from 'react-icons/bi'

export default function Queried (props) {
  const redirect = (url) => {
    window.open(url, '_self', 'noopener,noreferrer')
  }

  return (
    props.isQuery && (
      <Tooltip
        title={props.isQueryPending ? 'Buyer Awaiting Your Response' : 'Messaging in Progress'}
      >
        <IconButton
          data-testid='messagebutton'
          aria-label='pending-reply'
          className='activityButton'
          onClick={() => redirect(`/messenger/${props.catalogueId}`)}
        >
          {props.isQueryPending
            ? (
              <Badge color='success' variant='dot'>
                <BiMessageAltDetail size={30} color='#6495ED' />
              </Badge>
              )
            : (
              <BiMessageAltDetail size={30} color='#6495ED' data-testid='icon' />
              )}
        </IconButton>
      </Tooltip>
    )
  )
}
Queried.propTypes = {
  isQuery: PropTypes.bool,
  isQueryPending: PropTypes.bool,
  catalogueId: PropTypes.string
}
