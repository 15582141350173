import React from 'react'
import { CCol, CRow, CCarousel, CCarouselItem } from '@coreui/react'
import PropTypes from 'prop-types'
import './ImageCarousel.css'

const ImageCarousel = (props) => {
  return (
    <CCarousel dark transition='crossfade'>
      {props.items.map((item, index) => {
        return (
          <CCarouselItem key={index}>
            <CRow>
              <CCol>
                <img
                  src={item.image}
                  alt={index}
                  //   className="d-block w-100"
                 // height='300px'
                  width='200px'
                />
              </CCol>
            </CRow>
            <CRow className='mt-5'>
              <CCol>
                <div className='welcome-carousel-title'>{item.title}</div>
              </CCol>
            </CRow>
          </CCarouselItem>
        )
      })}
    </CCarousel>
  )
}

ImageCarousel.propTypes = {
  items: PropTypes.array
}

export default ImageCarousel
