import React from 'react'
import PropTypes from 'prop-types'

import './Notification.css'
import { Link } from 'react-router-dom'
const Notification = (props) => {
  return (
    <span className='system-notification-msg text-center'>
      <Link target='_blank' to={`/purchaseOrder/${props?.poNumber}`}>{props?.poNumber}</Link>
      &nbsp;&nbsp;&nbsp;{props.message}
    </span>
  )
}

Notification.propTypes = {
  message: PropTypes.string,
  poNumber: PropTypes.string
}

export default Notification
