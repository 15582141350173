import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './ProposalHistroy.css'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { Box, Skeleton } from '@mui/material'
import { getDate } from 'shared/util/helper'
import { useQuery } from 'react-query'


const ProposalHistory = (props) => {
  const [history, setHistory] = useState([])
  // const [isLoading, setIsLoading] = useState(false)
  const sku = props.sku
  const productExp = props.poDetails.products.find((p) => p?.productId === props?.pId)
  const {isLoading:isLoading } = useQuery(
    ['getpurchaseOrder', props.poDetails.poNumber],
    async () => await purchaseOrderService.getSinglePurchaseOrder(props.poDetails.poNumber),
    {
      onSuccess: (response) => {
        setProposedQty(response.data)
      },
      onError: (error) => {
        console.log(error)
      },
    },
    {
      enabled: props.poDetails.poNumber != null || undefined, // Enable query only when poNumber is available
    }
  )

  // const getPO = async () => {
  //   setIsLoading(true)
  //   try {
  //     await purchaseOrderService.getSinglePurchaseOrder(props.poDetails.poNumber).then(
  //       (response) => {
  //         setProposedQty(response.data)
  //         setIsLoading(false)
  //       },
  //       (error) => {
  //         setIsLoading(false)
  //       }
  //     )
  //   } catch (err) {
  //     setIsLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   getPO()
  // }, [])

  const setProposedQty = (PoData) => {
    const productIndex = PoData.products.findIndex((product) => product.productId === props.pId)
    if (productIndex !== -1) {
      setHistory(PoData.products[productIndex].proposedQty)
    }
  }

  // Render a single item in the list
  const renderItem = ({ item }) => (
    <tbody>
      <tr>
        <td>{sku}</td>
        <td>{productExp?.expectedQty}</td>
        <td>{item?.qty}</td>
        <td>{item?.user}</td>
        <td>{getDate(item?.updatedAt)}</td>
      </tr>
    </tbody>
  )

  return (
    <>
      {isLoading ? (
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation='wave' />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <div>
          <table className='table'>
            <thead>
              <tr>
                <th>SKU</th>
                <th>{'Expected\u00a0Qty'}</th>
                <th>{'Proposed\u00a0Qty'}</th>
                <th>Name</th>
                <th>{'Updated\u00a0At'}</th>
              </tr>
            </thead>

            {history.map((item) => renderItem({ item }))}
          </table>
        </div>
      )}
    </>
  )
}

export default ProposalHistory

ProposalHistory.propTypes = {
  products: PropTypes.array,
  pId: PropTypes.string,
  sku: PropTypes.string,
  poDetails: PropTypes.object
}
