import React from 'react'

const VerifyPage = React.lazy(() => import('pages/account/Verify'))
const Home = React.lazy(() => import('pages/Home'))

const routesList = [
  {
    path: '/verify/:token',
    name: 'E-Mail Verification',
    description: 'E-Mail Id Verification',
    component: <VerifyPage />,
    exact: true,
    checkLogin: false
  },
  {
    path: '/',
    name: 'Purchase Order Management',
    description:
      'A purchase order management web app streamlines the process of creating, approving, and tracking purchase orders, providing businesses with a centralized platform to manage their procurement activities and improve efficiency',
    component: <Home />,
    exact: true,
    checkLogin: false
  }
]

export default routesList
