// In Orders.js
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import useOrdersHooks from './useOrdersHooks'
import { Grid, Button, Snackbar, Typography } from '@mui/material'
import { Button as ProductButton } from '@mui/material'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { FiRefreshCcw } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import imageEmpty from 'assets/images/empty.png'
import CustomModal from 'components/uiElements/Modal'
import { UserContext } from 'context/AuthContext/UserContext'
import Select from '@mui/material/Select'
import OrderFilters from './OrderFilters'
import OrderTable from './OrderTable'
import Loader from 'components/common/Loader'
import { AppBreadcrumb } from 'sections/layout/dashboard'
import { TablePagination } from '@mui/material'
import Seo from 'components/common/seo'
const centerDivStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  textAlign: 'center',
}

const buttonStyle = {
  height: '40px',
  marginRight: '20px',
}
const messageStyle = {
  marginBottom: '30px', // Add space below the message
}
const iconStyle = {
  fontSize: '1.5rem',
}
const Orders = (props) => {
  const contextObj = React.useContext(UserContext)
  const {
    age,
    tokenError,
    refreshRetailToken,
    allOrder,
    retail,
    importModalOpen,
    isLoading,
    selectedOption,
    showNoOrdersToast,
    importResult,
    filters,
    currentPage,
    limit,
    setImportResult,
    closeImportModal,
    handleNoOrdersToastClose,
    downloadSampleCSV,
    handleSubmitBTN,
    readExcel,
    handleFilterChange,
    handleChange,
    handleFilterOptionChange,
    handlePreviousPage,
    handleNextPage,
    navigateToOrderDetails,
    openImportModal,
    orderCount,
    handleRowsPerPageChange,
  } = useOrdersHooks(props)



  return (
    <>

      <Seo
        title='Order List'
        description='Discover the World of Surya Purchase Order Management'
        image='https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png'
      />

      {
        <div>
          {tokenError === 401 && contextObj.userData.user.pomUserType == 3 ? (<div style={centerDivStyle}>
            <img
              src={imageEmpty}
              className='refresh_order'
            />
            <div>
              Your access token has expired!
            </div>
            <div style={messageStyle}>
              kindly refresh it to retrieve your orders.
            </div>
            <div>
              <Button variant='contained' style={buttonStyle} onClick={refreshRetailToken}>
                Refresh Token&nbsp; &nbsp;
                <FiRefreshCcw style={iconStyle} />
              </Button></div>
          </div>
          ) : (<>
            <div className='p-5'>
              <Grid container style={{ justifyContent: 'space-between' }}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <AppBreadcrumb />
                  <Typography variant='h5'>Order List</Typography>
                  <p>View & Manage your Order List</p>
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={12} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                  <div className='button-container'>
                    {(contextObj.userData.user.pomUserType !== 1 && contextObj.userData.user.pomUserType !== 4) ? (
                      <>
                        <ProductButton variant='outlined' component={Link} to='/order/create' style={{ height: '40px', marginRight: '20px' }}>
                          Create Order&nbsp;      &nbsp;
                          <AiOutlineAppstoreAdd size='1.5rem' />
                        </ProductButton>
                        <ProductButton
                          variant='outlined'
                          onClick={() => {
                            openImportModal()
                          }}
                          style={{ height: '40px', marginRight: '20px' }}
                        >
                          Import Excel&nbsp; (.xlsx)
                        </ProductButton>
                        <Select
                          value={selectedOption}
                          onChange={handleFilterOptionChange}
                          sx={{
                            height: '40px',
                            padding: '5px 10px',
                            backgroundColor: 'white',
                            borderRadius: '4px',
                            marginRight: '20px',
                            border: '1px solid rgba(25, 118, 210, 0.5)',
                            boxShadow: 'none',
                            color: '#1976d2',
                          }}
                          label="Filter"
                          className='custom-select-order'
                        >
                          <MenuItem value="export">Export Orders&nbsp; (.xlsx)</MenuItem>
                          <MenuItem value="All">All</MenuItem>
                          <MenuItem value="Today">Today</MenuItem>
                          <MenuItem value="Yesterday">Yesterday</MenuItem>
                        </Select>

                        <ProductButton variant='outlined' onClick={downloadSampleCSV} style={{ height: '40px' }}>
                          Order Template&nbsp; (.xlsx)
                        </ProductButton>
                      </>
                    ) : (<FormControl sx={{ m: 1, minWidth: 120 }} size="small" variant='outlined'>
                      <InputLabel id="demo-select-small-label">Shops</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={age}
                        label='Shops'
                        onChange={handleChange}
                      >
                        <MenuItem value={'All'}>All</MenuItem>
                        {retail.map((item) => (
                          <MenuItem key={item.id} value={item.shopName}>{item.shopName}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>)}
                  </div>
                </Grid>
              </Grid>

              <Snackbar
                open={importResult.savedCount > 0 || importResult.failedCount > 0 || importResult.duplicateCount > 0}
                message={`Saved ${importResult.savedCount} orders, Failed ${importResult.failedCount} orders, Duplicate ${importResult.duplicateCount} orders`}
                autoHideDuration={4000}
                onClose={() => setImportResult({ savedCount: 0, failedCount: 0, duplicateCount: 0 })}
              />
              <Snackbar
                open={showNoOrdersToast}
                autoHideDuration={4000}
                onClose={handleNoOrdersToastClose}
                message="No orders found for the selected date."
              />


              {(contextObj.userData.user && (contextObj.userData.user.pomUserType === 1 || contextObj.userData.user.pomUserType === 3 || contextObj.userData.user.pomUserType === 4)) && (
                <OrderFilters filters={filters} handleFilterChange={handleFilterChange} />
              )}
              {isLoading ? <Loader /> :
                <Grid>
                  <OrderTable orderTemp={allOrder} limit={limit} currentPage={currentPage} navigateToOrderDetails={navigateToOrderDetails} />
                  {allOrder?.length ? <TablePagination
                    className='mt-5'
                    component='div'
                    count={orderCount}
                    rowsPerPage={limit}
                    page={currentPage}
                    onPageChange={(event, newPage) => {
                      if (newPage > currentPage) {
                        handleNextPage()
                      } else {
                        handlePreviousPage()
                      }
                    }}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    data-testid='table-pagination'
                    rowsPerPageOptions={[10, 20, 30]}
                  /> : ("")}
                </Grid>}
            </div >

            <CustomModal
              open={importModalOpen}
              onClose={closeImportModal}
              title="Import Order"
              onSubmit={handleSubmitBTN}
              isLoading={isLoading}
            >
              <p>Choose a file to import:</p>
              <input type='file' onChange={(e) => {
                const file = e.target.files[0]
                readExcel(file)
              }} />
            </CustomModal>
          </>)
          }
        </div>}
    </>
  )
}

export default Orders
