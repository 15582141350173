import React from 'react'
import PropTypes from 'prop-types'

const QueryMessage = (props) => {
  return (
    <div className='sc-message--text'>
      <span className='small   float-start'>
        {props.userId === props.data.userId ? 'You' : props.data.userName}
      </span>
      <p className='pt-4'>QueryId: {props.data._id}</p>
      <span>Query : {props.data.message}</span>
    </div>
  )
}

QueryMessage.propTypes = {
  data: PropTypes.object,
  userId: PropTypes.string
}

export default QueryMessage
