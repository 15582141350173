import React from 'react'
import PropTypes from 'prop-types'

const TextMessage = (props) => {
  return (
    <div className='sc-message--text'>
      <span className='small  text-muted float-start'>
        {props.userId === props.data.userId ? 'You' : props.data.userName}
      </span>
      <p className='pt-3'>{props.data.message}</p>
    </div>
  )
}

TextMessage.propTypes = {
  data: PropTypes.object,
  userId: PropTypes.string
}

export default TextMessage
