import React from 'react'
import PropTypes from 'prop-types'

import TextMessage from './types/TextMessage'
import ShortlistMessage from './types/ShortlistMessage'
import ImageMessage from './types/ImageMessage'
import PdfMessage from './types/PdfMessage'
import QueryMessage from './types/QueryMessage'

const Message = (props) => {
  const renderMessageOfType = (type) => {
    switch (type) {
      case 'text':
        return <TextMessage data={props.message} userId={props.userId} />
      case 'image':
        return <ImageMessage data={props.message} userId={props.userId} />
      case 'pdf':
        return <PdfMessage data={props.message} userId={props.userId} />
      case 'list':
        return <ShortlistMessage data={props.message} />
      case 'query':
        return <QueryMessage data={props.message} userId={props.userId} />
      default:
        return <TextMessage data={props.message} userId={props.userId} />
    }
  }

  const contentClassList = [
    'sc-message--content',
    props?.message?.type === 'query' ? 'query' : props.message.userId === props.userId ? 'sent' : 'received'
  ]
  return (
    <div data-testid='message' className='sc-message'>
      <div className={contentClassList.join(' ')}>
        {props.message.userId !== props.userId && (
          <div
            className='sc-message--avatar'
            style={{
              backgroundImage: `url(${props.companyImg})`
            }}
          />
        )}
        {renderMessageOfType(props.message.type)}
        {/* {props.message.userId !== props.userId && (
            <div data-testid='messageType' className='sc-message--avatar'>
              {props.message.userName}
            </div>
          )} */}
      </div>
    </div>
  )
}

Message.propTypes = {
  message: PropTypes.object,
  companyImg: PropTypes.string,
  userId: PropTypes.string,
  image: PropTypes.object
}

export default Message
