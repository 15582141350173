import React from 'react'
import PropTypes from 'prop-types'
import { isNotNullUndefinedOrEmpty, isNotUndefinedAndNull } from 'shared/util/helper'

const ShipTo = (props) => {
  return (
    <>
      {isNotUndefinedAndNull(props.shipTo)
        ? (
          <>
            {isNotNullUndefinedOrEmpty(props.shipTo) && (
              <>
                <span>{props.shipTo}</span>
                <br />
              </>
            )}
          </>
          )
        : (
          <div className='cat-sub-title text-center'>---</div>
          )}
    </>
  )
}

ShipTo.propTypes = {
  shipTo: PropTypes.string
}

export default ShipTo
