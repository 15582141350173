import React, { useReducer, useCallback } from 'react'
import { Grid, Button as MUIButton } from '@mui/material'
import PropTypes from 'prop-types'

import Input from 'components/formElements/Input'
import Button from 'components/formElements/Button'
import { VALIDATOR_NUMERIC, VALIDATOR_REQUIRE } from 'shared/util/validators'

const formReducer = (state, action) => {
  switch (action.type) {
    case 'INPUT_CHANGE': {
      let formIsValid = true
      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid
        }
      }
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid }
        },
        isValid: formIsValid
      }
    }
    default:
      return state
  }
}

const ProposalForm = (props) => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      qty: {
        value: '',
        isValid: false
      }
    },
    isValid: false
  })

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: 'INPUT_CHANGE',
      value,
      isValid,
      inputId: id
    })
  }, [])
  const submitHandlerPo = () => {
    props.submitHandler(formState.inputs.qty.value, props.PoNumber, props.ProductId)
  }

  const product = props.poDetails.products.find((p) => p.productId === props.ProductId)

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' className='mb-4'>
      <Grid item sm={10}>
        <Input
          id='expectedQty'
          element='input'
          type='number'
          label='Expected Qty'
          validators={[VALIDATOR_REQUIRE()]}
          errorText='Please enter a valid First Name'
          onInput={inputHandler}
          value={product.expectedQty}
          isUpdate={false}
        />
      </Grid>
      <Grid item sm={10}>
        <Input
          id='qty'
          element='input'
          type='number'
          label='Proposed Qty'
          validators={[VALIDATOR_REQUIRE(), VALIDATOR_NUMERIC()]}
          errorText='Please enter a valid Proposed Qty'
          onInput={inputHandler}
          value={formState.inputs.qty.value}
          isUpdate
        />
      </Grid>

      <Grid item sm={12} className='mt-3'>
        <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
          <Grid item>
            <MUIButton variant='outlined' onClick={props.cancelAction}>
              Cancel
            </MUIButton>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              disabled={!formState.isValid}
              onClick={submitHandlerPo}
              // isLoading={props.isSubmitting}
              color='success'
              label='SAVE'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ProposalForm.propTypes = {
  cancelAction: PropTypes.func,
  submitHandler: PropTypes.func,
  PoNumber: PropTypes.string,
  ProductId: PropTypes.string,
  isSubmitting: PropTypes.bool,
  poDetails: PropTypes.array
}

export default ProposalForm
