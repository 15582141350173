import React, { useContext, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumbs, } from '@mui/material'
import PropTypes from 'prop-types'
import routes from 'routes/DashboardRoutes'
import { UserContext } from 'context/AuthContext/UserContext'

const AppBreadcrumb = (props) => {
  const { pathNameDeclare } = props
  const currentLocation = useLocation().pathname
  const { userData } = useContext(UserContext)
  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute?.name
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      breadcrumbs.push({
        pathname: currentPathname,
        name: getRouteName(currentPathname, routes),
        active: index + 1 === array.length
      })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)
  const getHomeLink = (userData) => {
    try {
      const pomUserType = userData?.user?.pomUserType
      const Role = userData?.user?.role
      if (pomUserType === 2 || (pomUserType === 1 && Role <= 4)) {
        return <Link className='link-router' to={`/products`}>Home</Link>
      }
      else if (pomUserType === 4 || pomUserType === 3) {
        return <Link className='link-router' to={`/orders`}>Home</Link>
      }
      else {
        return <Link className='link-router' to={`/`}>Home</Link>
      }
    }
    catch (error) {
    }
  }
  const homeLink = useMemo(() => getHomeLink(userData), [userData])

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' className='mb-2'>
        {/* {(userData?.user?.pomUserType === 2) ? <Link className='link-router' to={`/products`}>Home</Link> : ((userData?.user?.pomUserType === 4) || (userData?.user?.pomUserType === 3)) ? <Link className='link-router' to={`/orders`}>Home</Link> : <Link className='link-router' to={`/`}>Home</Link>} */}
        {homeLink}
        {!pathNameDeclare ? breadcrumbs?.map((breadcrumb, index) => {
          return (
            <Link
              key={index}
              className='link-router'
              to={breadcrumb.pathname}
              aria-current='page'
            >
              {breadcrumb.name}
            </Link>
          )
        }) : pathNameDeclare?.map((breadcrumb, index) => {
          return (breadcrumb?.url ? (<Link
            key={index}
            className='link-router'
            to={breadcrumb?.url}
            aria-current='page'
          >
            {breadcrumb?.name}
          </Link>) : (breadcrumb?.name)
          )
        })}
      </Breadcrumbs>
    </>
  )
}


AppBreadcrumb.propTypes = {
  pathNameDeclare: PropTypes.array
}

export default React.memo(AppBreadcrumb)
